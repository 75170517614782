<div class="page-container phone-padding-top">
  <div class="show-phone top-logo-container">
    <img class="logo" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg" />
  </div>
  <img class="logo hide-phone" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg" />
  <div class="show-phone your-subscription">
    <div>
      <div class="your-subcription-title">Your subscription</div>
      <div class="subscription-type">{{ user.type }}</div>
    </div>
    <div class="spacer"></div>
    <div>
      <div class="subscription-amount">
        <sup>$</sup>{{ user.type === "Builder" ? "195.00" : "88.00" }}
      </div>
      <div class="subscription-frequency">Per month</div>
    </div>
    <!-- <img class="down-arrow" src="../../../assets/images/down_arrow.png"> -->
    <div class="description">
      Please read about our
      <a target="”_blank”" href="/delivery">Delivery</a> and
      <a target="”_blank”" href="/returns">Return</a> statements for further
      information.
    </div>
  </div>
  <div class="inner-container">
    <div class="small-button" (click)="back()">Back</div>
    <h1 class="heading">Enter your payment details</h1>
    <p class="sub-heading">No commitment. Cancel anytime.</p>
    <div
      class="expand-box"
      [ngClass]="{ expanded: discountBoxExpanded }"
      (click)="discountBoxExpanded = !discountBoxExpanded"
    >
      <div class="title">Discount code</div>
      <img class="down-arrow" src="../../../assets/images/down_arrow.png" />
      <div *ngIf="discountDescription" class="description">
        {{ discountDescription }}
      </div>
      <div class="discount-input-row">
        <div
          class="input-field"
          [ngClass]="{ error: errors.discountCode }"
          (click)="$event.stopPropagation()"
        >
          <input
            [(ngModel)]="discountCode"
            (ngModelChange)="errors.discountCode = false"
            placeholder="Type here..."
            type="text"
          />
        </div>
        <div
          class="secondary-button small-button"
          [ngClass]="{ loading: loadingDiscountCode }"
          (click)="applyDiscountCode(); $event.stopPropagation()"
        >
          Apply
        </div>
      </div>
    </div>
    <div class="card-payment-container">
      <div class="card-payment-heading">
        <div class="card-title">Credit Card</div>
        <div class="payment-icons">
          <img src="../../../assets/images/visa_icon.png" />
          <img src="../../../assets/images/amex_icon.png" />
          <img src="../../../assets/images/mastercard_icon.png" />
        </div>
      </div>
      <div class="input-field" [ngClass]="{ error: errors.name }">
        <label for="name">Name On Card</label>
        <input
          id="name"
          [(ngModel)]="name"
          (ngModelChange)="validate()"
          placeholder="Type here..."
          type="text"
          autocomplete="name"
        />
      </div>
      <div class="input-field" [ngClass]="{ error: errors.cardNumber }">
        <label for="cardNumber">Card Number</label>
        <input
          id="cardNumber"
          [(ngModel)]="cardNumber"
          (ngModelChange)="validate()"
          placeholder="•••• •••• •••• ••••"
          type="text"
          maxlength="19"
          cc-number
        />
      </div>
      <div class="input-field-row">
        <div class="input-field" [ngClass]="{ error: errors.expiry }">
          <label for="expiry">Expiration (MM/YY)</label>
          <input
            id="expiry"
            [(ngModel)]="expiry"
            (ngModelChange)="validate()"
            placeholder="MM/YY"
            type="text"
            maxlength="5"
            cc-expiry
          />
        </div>
        <div class="input-field" [ngClass]="{ error: errors.cvc }">
          <label for="cvc">CVC</label>
          <input
            id="cvc"
            [(ngModel)]="cvc"
            (ngModelChange)="validate()"
            placeholder="Type here..."
            type="text"
            numbersOnly="true"
          />
        </div>
      </div>
      <div
        *ngIf="errorMessage"
        style="margin-bottom: 35px"
        class="error"
        [innerHTML]="errorMessage"
      ></div>
      <div class="note-row">
        <p>
          Payment will not be processed until your account details have been
          verified.
        </p>
      </div>
      <div class="pay-row">
        <img class="lock-icon" src="../../../assets/images/lock_icon.png" />
        <div class="secure-text">
          <div class="secure-text-1">Secure checkout</div>
          <div class="secure-text-2">Pay safely with SSL technology.</div>
        </div>
        <div class="spacer"></div>
        <div
          class="primary-button"
          [ngClass]="{ loading: loading }"
          (click)="signUp()"
        >
          Join FLRHUB
        </div>
      </div>
    </div>
  </div>
  <div class="subscription-container hide-phone">
    <div class="subscription-details">
      <div>
        <div class="subscription-heading">Your Subscription</div>
        <div class="subscription-name">{{ user.type }}</div>
        <!-- <div class="subscription-description">Perfect for the something like that and some text here explains
                    why buy this over the one</div> -->
      </div>
      <div>
        <div class="subscription-pricing">
          <sup>$</sup>{{ user.type === "Builder" ? "195.00" : "88.00" }}
        </div>
        <div class="subscription-frequency">Per month</div>
      </div>
    </div>
    <div class="fine-print">
      Please read our
      <a target="”_blank”" href="/delivery">Delivery Policy</a> and
      <a target="”_blank”" href="/returns">Return Policy</a> for further
      information.
    </div>
  </div>
</div>

<img class="background-logo" src="../../../assets/images/bg_logo.png" />
