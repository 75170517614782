import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import dist from 'gsap/dist';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { max } from 'rxjs';
import { CartItem } from 'src/app/classes/CartItem';
import { Order } from 'src/app/classes/Order';
import { Product } from 'src/app/classes/Product';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { ShippingService } from 'src/app/services/shipping.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss'],
})
export class ShippingComponent implements OnInit {
  errors: any = {};
  startValidation = false;
  currentDate = new Date().toISOString().split('T')[0];

  public productClass = Product;
  // public order: Order = new Order();

  options: Options = new Options({
    // bounds: undefined, fields: ["address_component"], strictBounds: false,
    // types: ['geocode','route'],
    componentRestrictions: { country: 'au' },
  });

  tarkettHandlingFee = 68;
  editingOrder = new Order();
  isEditingOrder = false;

  tempShippingPackages: any = {};
  shippingTotal: string = '$0.00';

  deliveryOptions = [
    {
      title: 'Standard Metro Delivery',
      value: 'standardMetro',
      price: '$250',
      isRegional: false,
    },
    {
      title: 'Express Metro Delivery',
      value: 'expressMetro',
      price: '$500',
      isRegional: false,
    },
    {
      title: 'Regional Delivery',
      value: 'regional',
      price: 'Contact for quote',
      isRegional: true,
    },
    { title: 'Pick-up from warehouse', value: 'pickup', price: 'FREE' },
  ];

  siteContact = '';
  siteContactNumber = '';

  selectedDeliveryOption: string = 'pickup';
  showSiteSpecificFields: boolean = false;
  postcode: number = 0;
  isRegional: boolean = true;

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shippingService: ShippingService,
  ) {
    //this.waitForUserToLoad();

    AuthHelper.instance.isLoggedIn().then(() => {
      this.postcode = parseInt(this.order.shippingPostcode);
      this.selectDeliveryOption(this.order.shippingOption);
      //this.order = this.getOrder();
    });

    shippingService
      .calculateShipping(this.deliveryOptions[0].value)
      .toPromise()
      .then((res: any) => (this.shippingTotal = res.shippingTotal));
  }

  get order() {
    return this.user.currentOrder;
  }
  // getOrder(): Order {
  //   let order = new Order();
  //   AuthHelper.instance.userDetails().then((user) => {
  //     order.addData(user.currentOrder);
  //   });
  //   //order.addData(user.currentOrder);
  //   return order;
  // }

  async ngOnInit() {
    //  AnalyticsHelper.instance.pageView('Shipping');
    //  if (!this.isEditingOrder) {
    //    this.waitForUserToLoad();
    //  }
    console.log('Loading order data = ' + JSON.stringify(this.order));
  }

  calculateShipping(shippingOption: string, postcode?: number) {
    this.shippingService
      .calculateShipping(shippingOption, postcode ? postcode : this.postcode)
      .toPromise()
      .then((res: any) => {
        (this.shippingTotal = res.shippingTotal),
          (this.order.shippingOption = this.selectedDeliveryOption),
          (this.order.shippingTotal = this.shippingTotal),
          (this.isRegional = res.isRegional);
      });
  }

  async waitForUserToLoad() {
    try {
      await AuthHelper.instance.isLoggedIn();
      this.user.currentOrder.shippingTotal = this.shippingTotal;
      console.log(
        'current order for user = ' + JSON.stringify(this.user.currentOrder),
      );
      //this.splitShippingPackages();
    } catch (e) {
      console.log('Error fetching user');
    }
  }
  handleAddressChange(event: any) {
    if (!event.address_components[6]) return;

    console.log('Address changed:', event);
    this.postcode = event.address_components[6].long_name ?? 0;
    this.order.shippingStreetNumber = event.address_components[0].long_name;
    this.order.shippingStreet = event.address_components[1].long_name;
    this.order.shippingSuburb = event.address_components[2].long_name;
    this.order.shippingState = event.address_components[4].long_name;
    this.order.shippingCountry = event.address_components[5].long_name;
    this.order.shippingPostcode = event.address_components[6].long_name;
    this.calculateShipping(this.selectedDeliveryOption, this.postcode);
    this.order.shippingAddress = event.formatted_address;
    this.order.shippingLat = event.geometry.location.lat();
    this.order.shippingLng = event.geometry.location.lng();
    //this.splitShippingPackages();
  }

  selectDeliveryOption(option: string) {
    this.selectedDeliveryOption = option;
    this.showSiteSpecificFields = option === 'regional';
    this.calculateShipping(option);
  }

  dateChanged() {
    console.log('Date changed:', event);
    //this.order.deliveryDate = event.value;
  }
  capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  keysForObject(object: Object): string[] {
    return Object.keys(object);
  }

  validate() {
    if (!this.startValidation) return false;
    this.errors = {};
    if (!this.order.deliveryDate) {
      this.errors.deliveryDate = true;
    }
    if (!this.order.shippingName) {
      this.errors.shippingName = true;
    }
    if (
      !this.order.shippingPhone ||
      !UtilityHelper.isPhoneValid(this.order.shippingPhone)
    ) {
      this.errors.shippingPhone = true;
    }
    if (!this.order.shippingAddress) {
      this.errors.shippingAddress = true;
    }
    this.keysForObject(this.order.shippingPackages).forEach(
      (packageKey: any) => {
        // console.log('delivery option', this.order.shippingPackages[packageKey].deliveryOption.option)
        if (!this.order.shippingPackages[packageKey].deliveryOption.option) {
          this.errors['deliveryOption-' + packageKey] = true;
        }

        // run validation for the site delivery fields
        if (
          this.order.shippingPackages[packageKey].deliveryOption.option
            .toLowerCase()
            .includes('site delivery')
        ) {
          if (!this.order.shippingPackages[packageKey].siteContact) {
            this.errors['siteContact-' + packageKey] = true;
          }

          if (!this.order.shippingPackages[packageKey].siteContactNumber) {
            this.errors['siteContactNumber-' + packageKey] = true;
          }

          if (
            !this.order.shippingPackages[packageKey].deliverySiteOpeningTime
          ) {
            this.errors['siteOpenTime-' + packageKey] = true;
          }

          if (
            !this.order.shippingPackages[packageKey].deliverySiteClosingTime
          ) {
            this.errors['deliveryHours-' + packageKey] = true;
          }

          if (
            !this.order.shippingPackages[packageKey].deliveryDropOffLocation
          ) {
            this.errors['deliveryDropOffLocation-' + packageKey] = true;
          }
        }
      },
    );
    console.log(JSON.stringify(this.errors));
    return Object.keys(this.errors).length === 0;
  }

  next() {
    this.startValidation = true;
    if (this.validate()) {
      if (this.data) {
        DatabaseHelper.instance.dataCallbacks.dataCallback({
          page: 'payment',
          order: this.order,
        });
      } else {
        this.router.navigateByUrl('payment', { state: { order: this.order } });
        this.user.currentOrder = this.order;
        this.user.save();
      }
    }
  }

  //selectDeliveryOption(
  //  option: string,
  //  price: number,
  //  supplier: string,
  //  dispatchRegion: string,
  //  deliveryRegion: string,
  //  address: string,
  //  packingAndOrderCost?: number,
  //) {
  //  this.order.shippingPackages[supplier].deliveryOption = {
  //    option: option,
  //    cost: price + '',
  //    dispatchRegion: dispatchRegion,
  //    deliveryRegion: deliveryRegion,
  //    address: address ? address : '',
  //    packingAndOrderCost: packingAndOrderCost ? packingAndOrderCost : 0,
  //  };
  //  AnalyticsHelper.instance.logEvent('delivery_option_selected', {
  //    delivery_option: option,
  //    supplier: supplier,
  //    price: price,
  //  });
  //  this.validate();
  //}

  unitTypeToMeasure(unitType: string) {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get containsLessThan2WeldRodsOnly() {
    let bool = true;
    let weldRods = 0;
    this.order.cart.forEach((item) => {
      if (item.quantity > 0) {
        bool = false;
      } else {
        item.lineItems.forEach((lineItem) => {
          weldRods += lineItem.quantity;
        });
      }
    });
    return bool && weldRods <= 2;
  }

  back() {
    window.history.back();
  }

  get loading() {
    return !AuthHelper.instance.userLoaded;
  }
}
