import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartItem } from 'src/app/classes/CartItem';
import { Order } from 'src/app/classes/Order';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';
import { CreditCardComponent } from 'src/app/modals/credit-card/credit-card.component';
import { DeliveryComponent } from '../delivery/delivery.component';
import { OrderSubmittedComponent } from '../order-submitted/order-submitted.component';
import { OrderUpdatedComponent } from '../order-updated/order-updated.component';
import { PaymentComponent } from '../payment/payment.component';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { ProductsComponent } from '../products/products.component';
import { ReviewComponent } from '../review/review.component';
import { ShippingComponent } from '../shipping/shipping.component';
import { Product } from 'src/app/classes/Product';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  order: Order = new Order();
  productReplaced = false;
  loading = false;
  errors: any = {};
  public productClass = Product;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private orderService: OrderService,
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation()?.extras.state
    ) {
      this.order.addData(
        this.router.getCurrentNavigation()?.extras.state!['order'],
      );
    } else {
      this.router.navigateByUrl('/orders');
    }
  }

  getStockAvaliablityDescription(
    stockAvaliablity: string,
    dispatchDate: string,
  ) {
    if (stockAvaliablity == 'requestedDate') {
      return 'Available for delivery by ' + dispatchDate;
    } else if (stockAvaliablity == '7days') {
      return 'Available for dispatch within 7 days';
    } else if (stockAvaliablity == '30days') {
      return 'Available for dispatch within 30 days';
    } else if (stockAvaliablity == 'future') {
      return 'Available for delivery by ' + dispatchDate;
    } else if (stockAvaliablity == 'notAvailable') {
      return 'The supplier is not able to fulfil this product for your order.';
    } else if (stockAvaliablity == 'replacementProduct') {
      return 'Please update your order to confirm this replacement';
    } else if (stockAvaliablity == 'quantityUpdated') {
      return 'Quantity Updated - Update order to confirm this replacement';
    } else {
      return 'AWAITING STOCK CHECK';
    }
  }

  getTotalAllocated(batches: any[]) {
    if (batches) {
      var total = 0;
      batches.forEach((batch) => {
        total = total + batch.batchAllocated;
      });
      return total;
    } else {
      return 0;
    }
  }

  selectAnotherProduct(cartItem: CartItem) {
    AuthHelper.instance.editingOrder = this.order;

    var productsDialog: MatDialogRef<ProductsComponent, any>;
    var detailsDialog: MatDialogRef<ProductDetailsComponent, any>;

    productsDialog = this.dialog.open(ProductsComponent, {
      disableClose: true,
      height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
      enterAnimationDuration: '0',
      panelClass: ['choose-product-modal', 'no-padding'],
      id: 'products-modal',
      data: {
        order: this.order,
      },
    });

    DatabaseHelper.instance.dataCallbacks.dataCallback = (navEvent: any) => {
      console.log('Nav Event = ' + JSON.stringify(navEvent));
      console.log('Nav Event = ' + navEvent.page);

      if (navEvent.page == 'product-details') {
        console.log('Nav 2 = ' + navEvent.page);

        // this.dialog.closeAll();
        detailsDialog = this.dialog.open(ProductDetailsComponent, {
          disableClose: true,
          height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
          width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
          maxWidth: '100vw',
          maxHeight: '-webkit-fill-available',
          position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
          panelClass: ['product-details-modal', 'no-padding'],
          id: 'product-details-modal',
          data: {
            productCollection: navEvent.productCollection,
          },
        });
      } else if (navEvent.page == 'dismissProductDetails') {
        detailsDialog.close();
      } else if (navEvent.page == 'dismissProducts') {
        this.dialog.closeAll();
      } else if (navEvent.page == 'replaceProduct') {
        console.log('LETS DO THIS!');
        //Close all dialogs
        this.dialog.closeAll();
        //Remove existing product from order
        let originalProductIndex = this.order.cart.findIndex(
          (i) => i.product.productCode === cartItem.product.productCode,
        );
        this.order.cart.splice(originalProductIndex, 1);
        //Add new product to order
        var product = navEvent.product;
        var quantity = navEvent.quantity;
        let index = this.order.cart.findIndex(
          (i) => i.product.productCode === product.productCode,
        );
        if (index >= 0) {
          this.order.cart[index].product = product;
          if (quantity) {
            this.order.cart[index].quantity += quantity;
          } else {
            this.order.cart[index].quantity = 0;
          }
          this.order.cart[index].stockAvaliablity = 'quantityUpdated';
          CartItem.calculateTotal(this.order.cart[index]);
        } else {
          let item = new CartItem();
          item.product = product;
          if (quantity) {
            item.quantity = quantity;
          } else {
            item.quantity = 0;
          }
          CartItem.calculateTotal(item);
          CartItem.calculateSupplierTotal(item);
          CartItem.calculateSupplierTotalWithGst(item);
          item.stockAvaliablity = 'replacementProduct';

          this.order.cart.push(item);
        }
        this.productReplaced = true;
      } else {
        console.log('done with event');
      }
    };
  }
  isAddOn(cartItem: CartItem) {
    return this.order.cart.find((item) => cartItem.parentId);
  }
  excludeAddOns(cartItems: CartItem[]) {
    return cartItems.filter((item) => !item.product.isAddOn);
  }
  hasAddOns(cartItem: CartItem) {
    return (
      !cartItem.product.isAddOn &&
      this.order.cart.find((item) => cartItem.id === item.parentId)
    );
  }
  itemsAddOns(cartItem: CartItem) {
    return this.order.cart.filter((item) => item.parentId === cartItem.id);
  }

  async confirmOrder() {
    //If  product has been replaced then proceed to checkout then refresh data on complete
    this.loading = true;
    try {
      const confirmOrder = await this.orderService
        .confirmOrder(this.order)
        .toPromise();

      console.log('confirmOrder', confirmOrder);
      this.router.navigateByUrl('order-confirmed', {
        state: { order: this.order },
      });
      this.loading = false;
    } catch (err) {
      console.error('confirmOrder', err);
      this.loading = false;
    }

    return;
    DatabaseHelper.instance.submitUpdatedOrder(this.order).then((val) => {
      if (val.success) {
        console.log('this.productReplaced', this.productReplaced);
        if (this.productReplaced) {
          this.updateOrder();
        } else if (this.validateStockData()) {
          //Else if credit card take payment, send POs and emails and update order status
          if (this.order.paymentOption == 'Credit Card') {
            this.takeCreditCardPayment();
          }
          //Else send emails and update order status
          else {
            this.processOrderWithoutPayment();
          }
        } else {
          this.loading = false;
        }
      }
    });
  }

  validateStockData() {
    this.errors = {};
    var allValidated = true;
    this.order.cart.forEach((cartItem) => {
      console.log(
        'Cart item quantity: ' +
          cartItem.quantity +
          ' total allocated: ' +
          this.getTotalAllocated(cartItem.batches),
      );
      console.log('Cart item ', cartItem);
      if (cartItem.quantity !== this.getTotalAllocated(cartItem.batches)) {
        allValidated = false;
        this.errors[cartItem.product.productCode] = true;
      }
    });
    console.log('All validated : ', allValidated);
    return allValidated;
  }

  //proceed to checkout then refresh data on complete
  updateOrder() {
    var deliveryDialog: MatDialogRef<ShippingComponent, any>;
    var paymentDialog: MatDialogRef<PaymentComponent, any>;
    var reviewDialog: MatDialogRef<ReviewComponent, any>;
    var orderSubmittedDialog: MatDialogRef<OrderSubmittedComponent, any>;
    //Delivery
    deliveryDialog = this.dialog.open(ShippingComponent, {
      disableClose: true,
      height: 'calc(100vh - 35px)',
      width: 'calc(100vw - 62px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      enterAnimationDuration: '0',
      position: { bottom: '0', left: '32px' },
      panelClass: ['choose-product-modal', 'no-padding'],
      id: 'shipping-modal',
      data: {
        order: this.order,
      },
    });
    DatabaseHelper.instance.dataCallbacks.dataCallback = (navEvent: any) => {
      console.log('Nav Event = ' + JSON.stringify(navEvent));
      console.log('Nav Event = ' + navEvent.page);

      if (navEvent.page == 'payment') {
        console.log('Nav 2 = ' + navEvent.page);
        //Payment
        paymentDialog = this.dialog.open(PaymentComponent, {
          disableClose: true,
          height: 'calc(100vh - 35px)',
          width: 'calc(100vw - 62px)',
          maxWidth: '100vw',
          maxHeight: '-webkit-fill-available',
          position: { bottom: '0', left: '32px' },
          panelClass: ['product-details-modal', 'no-padding'],
          id: 'payment-modal',
          data: {
            order: navEvent.order,
          },
        });
      } else if (navEvent.page == 'review') {
        //Review
        reviewDialog = this.dialog.open(ReviewComponent, {
          disableClose: true,
          height: 'calc(100vh - 35px)',
          width: 'calc(100vw - 62px)',
          maxWidth: '100vw',
          maxHeight: '-webkit-fill-available',
          position: { bottom: '0', left: '32px' },
          panelClass: ['product-details-modal', 'no-padding'],
          id: 'review-modal',
          data: {
            order: navEvent.order,
          },
        });
      } else if (navEvent.page == 'order-submitted') {
        //Order submitted
        orderSubmittedDialog = this.dialog.open(OrderUpdatedComponent, {
          disableClose: true,
          height: 'calc(100vh - 35px)',
          width: 'calc(100vw - 62px)',
          maxWidth: '100vw',
          maxHeight: '-webkit-fill-available',
          position: { bottom: '0', left: '32px' },
          panelClass: ['product-details-modal', 'no-padding'],
          id: 'order-submitted-modal',
          data: {
            order: navEvent.order,
          },
        });
      } else if (navEvent.page == 'dismissDelivery') {
        this.dialog.closeAll();
      } else if (navEvent.page == 'dismissPayment') {
        paymentDialog.close();
      } else if (navEvent.page == 'dismissReview') {
        reviewDialog.close();
      } else if (navEvent.page == 'dismissOrderSubmitted') {
        this.dialog.closeAll();
      } else {
        console.log('done with event');
      }
    };
  }

  //take payment, send POs and emails and update order status
  takeCreditCardPayment() {
    this.loading = true;
    PaymentsHelper.instance
      .captureAuthorization(this.order, this.user)
      .then(async (paymentData) => {
        if (!paymentData.error && paymentData.paymentStatus === 'APPROVED') {
          this.order.cardAuthorization = paymentData;
          await DatabaseHelper.instance.submitUpdatedOrder(this.order);
          DatabaseHelper.instance
            .processOrderWithPayment(this.order)
            .then((val) => {
              if (val.success) {
                this.loading = false;
                this.order.paymentStatus = 'PAID';
                this.router.navigateByUrl('order-confirmed', {
                  state: { order: this.order },
                });
              } else {
                this.loading = false;
                // TODO - Show alert
              }
            });
        } else {
          this.loading = false;
          this.handleCardPaymentError();
        }
      });
  }

  handleCardPaymentError() {
    this.dialog.open(AlertViewComponent, {
      disableClose: false,
      maxWidth: '400px',
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: 'We could not process the payment.',
        message:
          'The order total might have changed or the card has insufficient funds. Card used: ' +
          '**** ' +
          this.order.cardAuthorization.card.last4,
        primaryButton: 'Try again',
        secondaryButton: 'Cancel',
      },
    });
    DatabaseHelper.instance.dataCallbacks.alertViewCallback = (
      button: string,
    ) => {
      if (button === 'Try again') {
        this.dialog.open(CreditCardComponent, {
          disableClose: false,
          maxWidth: '600px',
          maxHeight: '-webkit-fill-available',
          panelClass: ['profile-modal', 'no-padding', 'no-animation'],
          id: 'credit-card-modal',
        });
        DatabaseHelper.instance.dataCallbacks.dataCallback = (
          cardDetails: any,
        ) => {
          PaymentsHelper.instance.reverseAuthorizedPayment(
            this.order.cardAuthorization.id,
          );
          if (cardDetails.card) {
            this.loading = true;
            PaymentsHelper.instance
              .authorizePayment(this.order, this.user, cardDetails.id)
              .then(async (val) => {
                if (val.error || val.paymentStatus !== 'APPROVED') {
                  this.loading = false;
                  this.handleCardPaymentError();
                  return;
                }
                this.order.cardAuthorization = val;
                await DatabaseHelper.instance.submitUpdatedOrder(this.order);
                this.takeCreditCardPayment();
              });
          }
        };
      }
    };
  }

  //send emails and update order status
  processOrderWithoutPayment() {
    this.loading = true;
    DatabaseHelper.instance
      .processOrderWithoutPayment(this.order)
      .then((val) => {
        if (val.success) {
          this.loading = false;
          this.order.paymentStatus = 'UNPAID';
          this.router.navigateByUrl('order-confirmed', {
            state: { order: this.order },
          });
        } else {
          this.loading = false;
          // TODO - Show alert
        }
      });
  }

  downloadInvoice() {}

  issue() {
    window.open(
      'mailto:team@flrhub.com.au?subject=Issue%20with%20order%20#' +
        this.order.orderNumber,
    );
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Order Details');
    console.log('Cart Details');
    console.log(this.order.cart);
  }

  back() {
    window.history.back();
  }

  unitTypeToMeasure(unitType: string) {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }
}
