
<div class="page-wrapper">

  <header id="masthead">
      <div class="inner boxed center-logo">
        <div class="site-logo"><a href="/"><img src="/assets/images/FLRHUB-Logo_UPDATED.svg" width="165" height="50" alt="Flrhub Logo"/></a></div>
      </div>
  </header><!-- #masthead -->

  <div class="page-content">



      <section id="entry-content" class="entry-content boxed">
          <div class="container">
              <div class="heading">
                  <h1>Returns Policy</h1>
                  <div class="context hierarchy-list">
                      <p>This Returns Policy (“Policy”), as amended or replaced from time to time, applies to the return of Goods supplied by FLRHUB to the Customer, or to any third party on the Customer’s behalf (“Customer”).</p>

<ol>
  <li>CONSUMER GUARANTEES
      <ol>
          <li>If you believe an item is faulty, you may have rights to a remedy under the Australian Consumer Law. The Australian Consumer Law does recognise that the relevant time period may vary by product (or service) depending on the nature of the goods (or service), the price paid and any representations made about the goods (or service).</li>
      </ol>
  </li>
  <li>RETURN OF GOODS
      <ol>
          <li>The Customer may reject and return Goods to FLRHUB, and FLRHUB may offer a refund for such Goods, provided that:
              <div class="alpha-list">
                  <div class="list"> the Goods are damaged in transit, wrongly supplied, defective or not in accordance with the Terms and Conditions of Sale;</div>
                  <div class="list"> the Customer sends FLRHUB photographic evidence of such damaged or defective Goods, or wrongly supplied Goods or Goods that do not comply with these Terms, within forty-eight (48) hours of delivery of those Goods to the Customer;</div>
                  <div class="list"> the Goods are returned within fourteen (14) days of the date of the invoice or account issued by FLRHUB to the Customer in respect of those Goods;</div>
                  <div class="list"> the Customer notifies FLRHUB of the invoice or account number in respect of the Goods to be returned; and</div>
                  <div class="list"> the Goods are returned in the original state or condition in which they were supplied, and remain in original boxes (where applicable) together with all packaging and instruction material.</div>
              </div>
          </li>
          <li>All Goods returned by the Customer are subject to inspection and assessment by FLRHUB and/or the relevant manufacturer(s), and FLRHUB may, if permitted by the Australian Consumer Law or any other law, refuse to accept the return of the Goods at its sole discretion.</li>
          <li>Please do not sign for the delivery of Goods that are obviously damaged. If damage is only found upon unpacking then please contact FLRHUB as soon as possible, and no later than forty-eight (48) hours of delivery of those Goods.</li>
          <li>Unless FLRHUB and the Customer otherwise agree in writing, the Customer will bear the costs of delivery when returning any Goods. In all cases, the return of Goods must be organised through FLRHUB. Goods returned through any other means will not be accepted by FLRHUB.</li>
          <li>Subject to the Customer’s rights under the Australian Consumer Law, the following Goods cannot be returned by the Customer to FLRHUB (and no refund will be provided by FLRHUB) under any circumstances:
            <div class="alpha-list">
              <div class="list">those that were specially made, sourced, ordered or purchased for the Customer;</div>
              <div class="list">those that were used, installed, laid, damaged or altered in any way by the Customer;</div>
              <div class="list">those that were sold to the Customer at wholesale or discount prices, or as second grade or quality; or</div>
              <div class="list">those that have been discontinued.</div>
            </div>
          </li>
          <li>To the extent permitted by law, all warranties whether implied or otherwise, not set out in this Policy are excluded and FLRHUB is not liable (including, without limitation, in contract, negligence or breach of statutory duty) to compensate the Customer for: any increased cost or expense, any loss of profit, revenue, business, contracts or anticipated saving, any loss or expense resulting from a claim by a third party; or any special, indirect or consequential loss or damage of any nature whatsoever.</li>
          <li>Other than as expressly stated in this Policy, under no circumstances will FLRHUB provide a refund for Goods.</li>
      </ol>
  </li>
  <li>DEFINITIONS
    <ol>
      <li>In this Policy unless the contrary intention appears:
        <div class="alpha-list">
          <div class="list"><strong>“Australian Consumer Law”</strong> means the Australian Consumer Law contained in Schedule 2 of the Competition and Consumer Act 2010 (Cth);</div>
          <div class="list"><strong>“FLRHUB”</strong> means FLRHUB Pty Ltd and any and all of its “Related Bodies Corporate” as such term is defined in the Corporations Act 2001 (Cth);</div>
          <div class="list"><strong>“Goods”</strong> means any goods FLRHUB supplies to the Customer whether on consignment or otherwise under the Terms and Conditions of Sale. They include goods described on any invoice, quotation, work authorisation or any other forms or notification FLRHUB provides the Customer or any order the Customer gives FLRHUB.</div>
          <div class="list"><strong>“Terms and Conditions of Sale”</strong> means FLRHUB’s Terms and Conditions of Sale, as amended or replaced from time to time, which applied to the supply of Goods to the Customer, and in the case of a trade customer, means, where applicable, the terms and conditions of sale incorporated into the Commercial Credit Trade Account the extent of any inconsistency or conflict with the Terms and Conditions of Sale.</div>
          <div class="list">Any capitalised term used in this Policy which is not otherwise defined in this Policy but is defined in the Terms and Conditions of Sale has the same meaning as in the Terms and Conditions of Sale.</div>
        </div>
      </li>
    </ol>
  </li>

</ol>


                  </div>
              </div>
          </div>
      </section><!-- .entry content -->





      <section id="footer" class="section-footer color-white reveal-blocks">
          <div class="container">
              <h2 class="h1">Any questions?</h2>
              <h3>Please email us at <a href="mailto:team@flrhub.com.au" target="_blank">team@flrhub.com.au</a></h3>
          </div>
          <div class="copyright-lines">
              <div class="inner">
                  <div class="copy">&copy;2001-<span id="current-year">2022</span> All Rights Reserved. FLRHUB®</div>
                  <nav class="links">
                      <ul class="ul-reset">
                          <li><a href="/privacy/">Privacy</a></li>
                          <li><a href="/terms/">Terms</a></li>
                      </ul>
                  </nav>
              </div>
          </div>
      </section>



  </div><!-- .page-content -->
</div><!-- .page-wrapper -->
