import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Order } from 'src/app/classes/Order';
import { Product } from 'src/app/classes/Product';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  editingOrder = new Order();
  isEditingOrder = false;

  loading = false;
  errorMessage = '';

  cardToken = '';

  public productClass = Product;

  constructor(
    private router: Router,
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.isEditingOrder = false;
    if (this.data) {
      this.editingOrder = new Order();
      this.editingOrder.addData(data.order);
      this.isEditingOrder = true;
      console.log('Loading order data = ' + JSON.stringify(this.order));
    } else if (
      this.router.getCurrentNavigation()?.extras &&
      this.router.getCurrentNavigation()?.extras.state
    ) {
      this.cardToken =
        this.router.getCurrentNavigation()?.extras.state!['cardToken'];
    } else {
      this.router.navigate(['/cart']);
    }
  }

  get order() {
    return this.isEditingOrder ? this.editingOrder : this.user.currentOrder;
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Review');
  }

  async submitOrder() {
    try {
      this.loading = true;
      this.errorMessage = '';
      //NOTE: This is all now in the backend
      // this.order.orderDate = new Date().getTime().toString();
      // this.order.paymentStatus = 'Unpaid';
      // this.order.fulfilmentStatus = 'AWAITING STOCK CHECK';
      this.order.setupTotals();

      if (this.order.paymentOption === 'Credit Card') {
        const cardPayment = await PaymentsHelper.instance.authorizePayment(
          this.order,
          this.user,
          this.cardToken,
        );
      }
    } catch (error) {
      this.loading = false;
      this.errorMessage =
        'We were unable to authorise your card payment. Please check your card details and try again.';
      console.log('Got error = ' + JSON.stringify(error));
      return;
    }
    this.orderService
      .submitOrder(this.order)
      .toPromise()
      .then((submitOrder) => {
        this.user.currentOrder = new Order();
        this.user.save();
        this.loading = false;
        this.router.navigateByUrl('order-submitted', {
          //state: { order: submitOrder.order },
        });
      })
      .catch((error) => {
        console.error('Got error = ' + JSON.stringify(error));
        this.loading = false;
        this.errorMessage =
          'We were unable to submit your order. Please try again later.';
      });
  }

  async next() {
    this.loading = true;
    this.errorMessage = '';
    this.order.userId = this.user.uid;
    this.order.orderDate = new Date().getTime().toString();
    this.order.paymentStatus = 'Unpaid';
    this.order.fulfilmentStatus = 'AWAITING STOCK CHECK';
    this.order.setupTotals();

    if (this.order.paymentOption === 'Credit Card') {
      let val = await PaymentsHelper.instance.authorizePayment(
        this.order,
        this.user,
        this.cardToken,
      );
      if (val.error || val.paymentStatus !== 'APPROVED') {
        this.loading = false;
        this.errorMessage =
          'We were unable to authorise your card payment. Please check your card details and try again.';
        console.log('Got error = ' + JSON.stringify(val));
        return;
      }
      this.order.orderNumber = val.orderNumber;
      this.order.cardAuthorization = val.simplifyData;
    }

    if (this.data) {
      DatabaseHelper.instance.submitUpdatedOrder(this.order).then((val) => {
        if (val.success) {
          if (this.data) {
            //User is updating an existing order through dialogs
            DatabaseHelper.instance.dataCallbacks.dataCallback({
              page: 'order-submitted',
              order: this.order,
            });
          } else {
            this.user.currentOrder = new Order();
            this.user.save();
            this.router.navigateByUrl('order-submitted', {
              state: { order: this.order },
            });
            if (this.order.discountCode) {
              DatabaseHelper.instance.redeemCoupon(
                this.user,
                this.order.discountCode,
                val.orderNumber,
              ); // Marks the coupon as redeemed
            }
          }
        } else {
          this.loading = false;
          // TODO - Show alert
        }
      });
    } else {
      DatabaseHelper.instance
        .submitOrder(this.order)
        .then((val) => {
          if (val.success) {
            if (this.data) {
              //User is updating an existing order through dialogs
              DatabaseHelper.instance.dataCallbacks.dataCallback({
                page: 'order-submitted',
                order: this.order,
              });
            } else {
              this.user.currentOrder = new Order();
              this.user.save();
              this.router.navigateByUrl('order-submitted', {
                state: { order: this.order },
              });
            }
          } else {
            this.loading = false;
            // TODO - Show alert
          }
        })
        .catch((error) => {
          console.error('Got error = ' + JSON.stringify(error));
          this.loading = false;
          this.errorMessage =
            'We were unable to submit your order. Please try again later.';
        });
    }
  }

  unitTypeToMeasure(unitType: string) {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  keysForObject(object: Object): string[] {
    return Object.keys(object);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  back() {
    window.history.back();
  }
}
