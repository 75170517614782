<div class="page-container phone-padding-top">
  <div class="show-phone top-logo-container">
    <img class="logo" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg" />
  </div>
  <img class="logo hide-phone" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg" />
  <div class="show-phone your-subscription">
    <div>
      <div class="your-subcription-title">Your subscription</div>
      <div class="subscription-type">{{ user.type }}</div>
    </div>
    <div class="spacer"></div>
    <div>
      <div class="subscription-amount">
        <sup>$</sup>{{ user.type === "Builder" ? "195.00" : "88.00" }}
      </div>
      <div class="subscription-frequency">Per month</div>
    </div>
    <!-- <img class="down-arrow" src="../../../assets/images/down_arrow.png"> -->
    <div class="description">
      Please read about our
      <a target="”_blank”" href="/delivery">Delivery</a> and
      <a target="”_blank”" href="/returns">Return</a> statements for further
      information.
    </div>
  </div>
  <div class="inner-container">
    <div class="small-button" (click)="back()">Back</div>
    <h1 class="heading">Let's grab your company details now</h1>

    <div class="input-field-row">
      <div class="input-field" [ngClass]="{ error: errors.companyName }">
        <label for="companyName">Company Name</label>
        <input id="companyName" [(ngModel)]="user.companyName" (ngModelChange)="validate()" placeholder="Type here..."
          type="text" />
      </div>
      <div class="input-field" [ngClass]="{ error: errors.abn }">
        <label for="abn">Company ABN</label>
        <input id="abn" [(ngModel)]="user.abn" (ngModelChange)="validate()" placeholder="Type here..." type="text"
          ABN="true" />
      </div>
    </div>

    <div class="input-field" [ngClass]="{ error: errors.companyAddress }">
      <label for="companyAddress">Company Address</label>
      <input id="companyAddress" ngx-google-places-autocomplete [(ngModel)]="user.companyAddress"
        (ngModelChange)="validate()" (onAddressChange)="handleAddressChange($event)" placeholder="Type here..."
        type="text" />
    </div>

    <div class="input-field-row upload-image-row">
      <div class="upload-image">
        <div class="preview-image-section">
          <div class="image-title">
            <b>Company Licence Details</b>
            {{ user.type === "Installer" ? "(If applicable)" : "" }}
            <br />
            (Building Licence, Trade Licence, Other)
          </div>
          <!-- <img class="preview-image"
                        [src]="user.companyLogoUrl ? user.companyLogoUrl :  '../../../assets/images/placeholder_image.png'"> -->
        </div>
        <div class="input-section">
          <label for="uploadLicence">
            <input id="uploadLicence" class="file-input" type="file" (change)="uploadDocument($event, 'licenceUrl')" />
            <div class="secondary-button" [ngClass]="{ loading: licenceUploading }">
              {{ user.licenceUrl ? "Change" : "Upload" }}
            </div>
            <div class="input-description size-error" *ngIf="errors.licenceUrl">
              Please upload your company licence
            </div>
          </label>
        </div>
      </div>
      <div class="upload-image">
        <div class="preview-image-section">
          <div class="image-title">
            <b>Insurance Details</b><br />(Work Cover, PL, PI)
          </div>
          <!-- <img class="preview-image"
                        [src]="user.companyLogoUrl ? user.companyLogoUrl :  '../../../assets/images/placeholder_image.png'"> -->
        </div>
        <div class="input-section">
          <label for="uploadInsurance">
            <input id="uploadInsurance" class="file-input" type="file"
              (change)="uploadDocument($event, 'insuranceUrl')" />
            <div class="secondary-button" [ngClass]="{ loading: insuranceUploading }">
              {{ user.insuranceUrl ? "Change" : "Upload" }}
            </div>
            <div class="input-description size-error" *ngIf="errors.insuranceUrl">
              Please upload your company insurance
            </div>
          </label>
        </div>
      </div>

      <!-- <div class="upload-image">
                <div class="preview-image-section">
                    <div class="image-title">Company Logo</div>
                    <img class="preview-image"
                        [src]="user.companyLogoUrl ? user.companyLogoUrl :  '../../../assets/images/placeholder_image.png'">
                </div>
                <div class="input-section">
                    <label for="uploadImage">
                        <input id="uploadImage" class="file-input" type="file" accept="image/*"
                            (change)="uploadImage($event)">
                        <div class="secondary-button" [ngClass]="{loading: imageUploading}"><img class="icon"
                                style="transform: scaleY(-1);"
                                src="../../../assets/images/download_icon.png">{{user.companyLogoUrl ?
                            'Change':'Upload'}} image</div>
                        <div class="input-description" [ngClass]="{'size-error': imageSizeError}">The image should be at
                            least 300px in width and height</div>
                    </label>
                </div>
            </div> -->
      <!-- <div>
                <div class="input-field" [ngClass]="{error: errors.businessLicenceNumber}">
                    <label for="businessLicenceNumber">Business Licence Number</label>
                    <input id="businessLicenceNumber" [(ngModel)]="user.businessLicenceNumber" (ngModelChange)="validate()"
                        placeholder="Type here..." type="text">
                </div>
                <div class="input-field" style="margin-bottom: 0;" [ngClass]="{error: errors.companyPhone}">
                    <label for="companyPhone">Company Phone Number</label>
                    <input id="companyPhone" [(ngModel)]="user.companyPhone" (ngModelChange)="validate()"
                        placeholder="Type here..." type="tel" numbersOnly="true" maxlength="10">
                </div>
            </div> -->
    </div>

    <div class="input-field" [ngClass]="{ error: errors.companyBankrupcy }">
      <label for="companyBankrupcy" class="field-title">Have you, your company or any company you've been part of
        declared
        bankruptcy in the past?</label>
      <div class="radio-choice">
        <div class="radio-item">
          <input id="companyBankrupcyYes" type="radio" [(ngModel)]="user.companyBankrupcy" (ngModelChange)="validate()"
            value="Yes" name="companyBankrupcy" />
          <label for="companyBankrupcyYes" class="radio-label">Yes</label>
        </div>
        <div class="radio-item">
          <input id="companyBankrupcyNo" type="radio" [(ngModel)]="user.companyBankrupcy" (ngModelChange)="validate()"
            value="No" name="companyBankrupcy" />
          <label for="companyBankrupcyNo" class="radio-label">No</label>
        </div>
      </div>
      <div class="input-description size-error" *ngIf="errors.companyBankrupcy">
        {{ errors.companyBankrupcyMessage }}
      </div>
    </div>
    <div *ngIf="errorMessage.length > 0" style="margin-bottom: 35px;">
      <div class="error" *ngFor="let error of errorMessage"><img class="error-icon"
          src="../../../assets/images/cross_icon.png">{{error}}</div>
    </div>
    <div style="display: flex; justify-content: end">
      <div class="primary-button" [ngClass]="{ loading: loading }" (click)="signup()">Next</div>
    </div>
  </div>
</div>

<img class="background-logo" src="../../../assets/images/bg_logo.png" />
