<div class="page-container phone-padding-top">
    <div class="show-phone top-logo-container">
        <img class="logo" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg">
    </div>
    <img class="logo hide-phone" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg">
    <div class="inner-container">
        <h1 class="heading">Sign Up for <b>FLRHUB</b></h1>
        <p class="sub-heading">Already have a FLRHUB account? <a href="/login">Log in here</a></p>
        <div class="select-boxes">
            <div class="select-box" [ngClass]="{selected: user.type === 'Builder'}"
                (click)="user.type = 'Builder'; validate();">
                <div class="select-description">
                    <div class="title">I’m a builder</div>
                    <!-- <div class="description">Explanation to clarify what this means and such</div> -->
                </div>
                <div class="check-box">
                    <img src="../../../assets/images/check_icon.png">
                </div>
            </div>
            <div class="select-box" [ngClass]="{selected: user.type === 'Installer'}"
                (click)="user.type = 'Installer'; validate();">
                <div class="select-description">
                    <div class="title">I’m an installer</div>
                    <!-- <div class="description">Explanation to clarify what this means and such</div> -->
                </div>
                <div class="check-box">
                    <img src="../../../assets/images/check_icon.png">
                </div>
            </div>
        </div>
        <div class="input-field">
            <label for="email">Email Address</label>
            <input id="email" [(ngModel)]="user.email" (ngModelChange)="validate()" placeholder="Type here..."
                type="email">
        </div>
        <div class="input-field password-field">
            <label for="password">Create Password</label>
            <input id="password" [(ngModel)]="password" (ngModelChange)="validate()" placeholder="Type here..."
                type="password">
        </div>
        <div *ngIf="errorMessage.length > 0" style="margin-bottom: 35px;">
            <div class="error" *ngFor="let error of errorMessage"><img class="error-icon"
                    src="../../../assets/images/cross_icon.png">{{error}}</div>
        </div>
        <p class="terms">By creating an account, you agree to our <a target=”_blank” href="/terms">Terms</a> and have read and acknowledge the <a target=”_blank” href="/privacy">Global Privacy
            Statement</a>.</p>
        <div class="primary-button"
             [ngClass]="{ loading: loading }"
             (click)="signup()"
        >Sign Up</div>
    </div>
</div>

<img class="background-logo" src="../../../assets/images/bg_logo.png">
