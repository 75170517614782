import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { Order } from 'src/app/classes/Order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private httpService: HttpService) {}

  confirmOrder(order: Order) {
    return this.httpService.post('/confirm-order', {
      response: 'json',
      body: order,
    });
  }

  submitOrder(order: Order) {
    return this.httpService.post('/submitOrder', {
      response: 'json',
      body: { order },
    });
  }
  //  getOrders() {
  //    return this.httpService.get('/orders', {
  //      response: 'json',
  //    });
  //  }
  //  getOrder(orderId: string) {
  //    return this.httpService.get(`/order/${orderId}`, {
  //      response: 'json',
  //    });
  //  }
  //
  //  createOrder(order: any) {
  //    return this.httpService.post('/order', {
  //      response: 'json',
  //      body: order,
  //    });
  //  }
  //
  //  updateOrder(order: any) {
  //    return this.httpService.put(`/order/${order.id}`, {
  //      response: 'json',
  //      body: order,
  //    });
  //  }
  //
  //  deleteOrder(orderId: string) {
  //    return this.httpService.delete(`/order/${orderId}`, {
  //      response: 'json',
  //    });
  //  }
}
