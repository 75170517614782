<div class="page-wrapper">
  <header id="masthead">
    <div class="inner boxed" style="background-color: var(--flrhub-green);">
      <div class="site-logo">
        <a href="/"
          ><img
            src="/assets/images/FLRHUB-Logo_UPDATED.svg"
            width="165"
            height="50"
            alt="Flrhub Logo"
        /></a>
      </div>
      <nav class="primary">
        <ul class="ul-reset">
          <li><a style="color: var(--light-light-grey);" href="#features">Features</a></li>
          <li><a style="color: var(--light-light-grey);" href="#pricing">Pricing</a></li>
        </ul>
      </nav>
      <nav class="secondary">
        <ul class="ul-reset">
          <li>
            <a style="color: var(--light-light-grey);" href="/login/" gaEvent="login_landing" gaCategory="click_event"
              >Log In</a
            >
          </li>
          <li>
            <a
              href="#pricing"
              gaEvent="get_started_landing"
              gaCategory="click_event"
              class="primary-button"
              >Get Started</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- #masthead -->

  <div class="page-content">
    <div class="hero-feattures -anim-container">
      <section id="hero" class="hero">
        <div class="context boxed">
          <div class="inner">
            <h1 class="-anim">The future of flooring</h1>
            <p class="teaser -anim">
              FLRHUB is a dedicated community for the commercial B2B flooring
              sector. From purchasing of materials and equipment to preparing
              flooring plan estimations and project planning support, FLRHUB is
              an all-in-one, secure HUB for everyone in the flooring industry.
            </p>
            <div class="cta -anim">
              <a
                class="play-video"
                href="/assets/images/landing/FLRHUBVID.mp4"
                target="_blank"
                >Watch Video</a
              >
            </div>
          </div>
        </div>
        <div class="image boxed"></div>
      </section>
      <!-- .hero -->

      <section id="feature-cards" class="feature-cards">
        <div class="row">
          <div class="card boxed">
            <div class="inner">
              <div class="image -anim">
                <img
                  src="/assets/images/landing/feature-1.png"
                  width="241"
                  height="183"
                  alt="Flrhub Feature 1"
                />
              </div>
              <h2 class="-anim">
                Buy flooring like<br />
                never before
              </h2>
              <p class="-anim">
                Browse, buy and get products delivered directly from suppliers
                such as Tarkett, Interface and more.
              </p>
            </div>
          </div>
          <!-- .card -->
          <div class="card boxed">
            <div class="inner">
              <div class="image -anim">
                <img
                  src="/assets/images/landing/feature-2-1.png"
                  width="167"
                  height="162"
                  alt="Flrhub Feature 2"
                />
              </div>
              <h2 class="-anim">
                Find jobs and streamline<br />
                your workflows
              </h2>
              <p class="-anim">
                Whether you're a builder looking for an installer to get
                flooring done, or an installer looking for work, FLRHUB has got
                you covered.
              </p>
            </div>
          </div>
          <!-- .card -->
          <div class="card boxed">
            <div class="inner">
              <div class="image -anim">
                <img
                  src="/assets/images/landing/feature-3.png"
                  width="112"
                  height="125"
                  alt="Flrhub Feature 3"
                />
              </div>
              <h2 class="-anim">
                Get flooring estimations,<br />
                fast and easy
              </h2>
              <p class="-anim">
                Estimations made simple. Just upload your plans, pick your
                materials and estimations come back to you within 72 hours.
              </p>
              <p class="-anim">
                <small><em>Additional charges apply.</em></small>
              </p>
            </div>
          </div>
          <!-- .card -->
          <div class="card boxed">
            <div class="inner">
              <div class="image -anim">
                <img
                  src="/assets/images/landing/feature-4.png"
                  width="132"
                  height="132"
                  alt="Flrhub Feature 4"
                />
              </div>
              <h2 class="-anim">
                Engage with the Community Hub (Coming Soon)
              </h2>
              <p class="-anim">
                FLRHUB's Community Hub provides a social platform for our
                installation and trade clients to engage with other contractors.
                Join forces and leverage your individual skill-sets to bid for
                jobs.
              </p>
            </div>
          </div>
          <!-- .card -->
        </div>
        <!-- .row -->
      </section>
      <!-- .feature-cards -->
    </div>

    <section id="partner-logo" class="partner-logo reveal-blocks">
      <div class="rolling-logo">
        <ul class="ul-reset ul-logo">
          <li>
            <img
              src="/assets/images/landing/logo-1.png"
              height="28"
              width="128"
              alt="Tarkett"
            />
          </li>
          <li>
            <img
              src="/assets/images/landing/logo-2.png"
              height="25"
              width="135"
              alt="Interface"
            />
          </li>
          <li>
            <img
              src="/assets/images/landing/logo-epoxy.png"
              height="30"
              width="178"
              alt="Expoxy Warehouse"
            />
          </li>
          <li>
            <img
              src="/assets/images/landing/logo-4.png"
              height="25"
              width="146"
              alt="Mainfreight"
            />
          </li>
          <li>
            <img
              src="/assets/images/landing/logo-5.png"
              height="24"
              width="96"
              alt="GMK Logistics"
            />
          </li>
          <li>
            <img
              src="/assets/images/landing/pentarch-forestry.png"
              height="50"
              width="139"
              alt="Pentarch Forestry"
            />
          </li>
          <li>
            <img
              src="/assets/images/landing/safety-docs.png"
              height="30"
              width="144"
              alt="Safety Docs"
            />
          </li>
          <li>
            <img
              src="/assets/images/landing/logo-gh.png"
              height="30"
              width="144"
              alt="GH Commercial"
            />
          </li>
        </ul>
      </div>
    </section>
    <!-- .partner-logo -->

    <section id="features" class="features boxed -anim-container">
      <div class="container">
        <div class="card-row row card-row-1">
          <div class="image">
            <div class="main-image">
              <img
                class="-anim"
                src="/assets/images/landing/main-feature-1-1.png"
                width="281"
                height="390"
                alt=""
              />
              <div class="ornament-image">
                <img
                  class="-anim-right"
                  src="/assets/images/landing/main-feature-1-slide.png"
                  height="362"
                  width="352"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="context">
            <div class="inner">
              <div class="strong -anim">Marketplace</div>
              <h2 class="-anim">Buy flooring like never before</h2>
              <p class="-anim">
                Browse, source and purchase flooring products seamlessly in our
                extensive product catalogue in partnership with our global
                brands, with flexible finance terms available to suit your
                needs.
              </p>
            </div>
          </div>
        </div>
        <!-- .card-row -->
        <div class="card-row row reverse card-row-2">
          <div class="image">
            <div class="main-image">
              <img
                class="-anim"
                src="/assets/images/landing/main-feature-2.png"
                width="281"
                height="390"
                alt=""
              />
              <div class="ornament-image">
                <img
                  class="-anim-left"
                  src="/assets/images/landing/main-feature-2-slide.png"
                  height="241"
                  width="401"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="context">
            <div class="inner">
              <div class="strong -anim">Job Board</div>
              <h2 class="-anim">Find jobs and streamline your workflows</h2>
              <p class="-anim">
                FLRHUB is the only dedicated JOB BOARD for the flooring
                industry. Flooring contractors can view, engage and complete
                quotes with ease via FLRHUB’s bidding options. FLRHUB also
                assists with estimations and take-offs for contractors, builders
                and construction companies.
              </p>
            </div>
          </div>
        </div>
        <!-- .card-row -->
        <div class="card-row row card-row-3">
          <div class="image">
            <div class="main-image">
              <img
                class="-anim"
                src="/assets/images/landing/main-feature-3-1.png"
                width="281"
                height="390"
                alt=""
              />
              <div class="ornament-image">
                <img
                  class="-anim-right"
                  src="/assets/images/landing/main-feature-3-slide.png"
                  height="258.5"
                  width="241"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="context">
            <div class="inner">
              <div class="strong -anim">Estimations</div>
              <h2 class="-anim">Get flooring estimations, fast and easy</h2>
              <p class="-anim">
                Don't have time to work out how much material you need? FLRHUB
                provides detailed flooring estimations on all materials enabling
                you to automatically purchase the exact quantities via FLRHUBs
                Marketplace. It’s as easy as uploading your plans, finalising
                your payment and within 72 hours max, have the estimates in
                hand!
              </p>
            </div>
          </div>
        </div>
        <!-- .card-row -->
      </div>
      <!-- .container -->
    </section>
    <!-- .features -->

    <section id="history" class="history color-white reveal-blocks">
      <div class="container">
        <h2 class="h1">
          From humble<br />
          beginnings
        </h2>
        <div class="context">
          <div class="card">
            <p>
              The FLRHUB community wasn't one that happened overnight, this has
              been somewhat in the making for the past two decades…The founders
              collaborated the strengths and knowledge in both commercial
              flooring and digital/technology space to bring FLRHUB into
              reality.
            </p>
          </div>
          <div class="card">
            <p>
              All the heavy lifting has been done! FLRHUBs motto "The future of
              flooring” is now a reality and we will continue to be innovative
              and agile in our approach.
            </p>
            <p>Welcome to the FLRHUB Community!</p>
          </div>
        </div>
      </div>
      <!-- .container -->
    </section>
    <!-- .history -->

    <section id="pricing" class="pricing boxed -anim-container">
      <div class="container">
        <div class="heading row-pricing -anim">
          <div class="pricing-context">
            <h2>
              Choose your<br />
              subscription
            </h2>
            <div class="promotion">
              <p>Take advantage of our launch Promotion and<br>receive your first 3 months subscription 100% free of charge!<br>Use code: <b>FLRHUBPROMO24</b> during sign up.</p>
            </div>
          </div>
          <div class="pricing-options">
            <div class="head">
              <h3>Installation <br />Contractors</h3>
              <!-- <p>Perfect for the something like that and some text here explains why buy this over the one</p> -->
              <div class="foot-price">
                <div class="price">
                  <span class="symbol">$</span><span class="big">88</span
                  ><span class="small">.00</span>
                  <div class="duration">Per month</div>
                </div>
                <!-- <div class="price-tba"><span>PRICE</span>TBA</div> -->
                <div class="cta">
                  <a
                    href="/sign-up?type=Installer"
                    class="primary-button"
                    gaEvent="sign_up_installer"
                    gaCategory="click_event"
                    >Sign Up</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- .pricing-option -->
          <div class="pricing-options">
            <div class="head">
              <h3>Builders & Building Contractors</h3>
              <!-- <p>The description may bit longer. Perfect for the something like that and some text here explains why buy this over the one</p> -->
              <div class="foot-price">
                <div class="price">
                  <span class="symbol">$</span><span class="big">195</span
                  ><span class="small">.00</span>
                  <div class="duration">Per month</div>
                </div>
                <!-- <div class="price-tba"><span>PRICE</span>TBA</div> -->
                <div class="cta">
                  <a
                    href="/sign-up?type=Builder"
                    class="primary-button"
                    gaEvent="sign_up_builder"
                    gaCategory="click_event"
                    >Sign Up</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- .pricing-option -->
        </div>
        <!-- .row-pricing -anim -->

        <div class="pricing-group-wrapper">
          <div class="pricing-group">
            <!-- <h2 class="-anim">Marketplace</h2> -->
            <div class="pricing-details">
              <div class="row-pricing -anim">
                <div class="pricing-context">
                  Marketplace (purchase materials, tools and equipment)
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
              </div>
              <div class="row-pricing -anim">
                <div class="pricing-context">
                  Tender for jobs via our Job Board
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark no"></span>
                </div>
              </div>
              <div class="row-pricing -anim">
                <div class="pricing-context">Post jobs via our Job Board</div>
                <div class="pricing-options">
                  <span class="checkmark no"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
              </div>
              <div class="row-pricing -anim">
                <div class="pricing-context">
                  Engage within our Community Hub (coming soon)
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark no"></span>
                </div>
              </div>
              <div class="row-pricing -anim">
                <div class="pricing-context">Access to Estimations</div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
              </div>
              <!-- <div class="row-pricing -anim">
                              <div class="pricing-context">Project pricing</div>
                              <div class="pricing-options"><span class="checkmark no"></span></div>
                              <div class="pricing-options"><span class="checkmark yes"></span></div>
                          </div> -->
              <!-- <div class="row-pricing -anim">
                              <div class="pricing-context">Multiple login options</div>
                              <div class="pricing-options"><span class="checkmark no"></span></div>
                              <div class="pricing-options"><span class="checkmark yes"></span></div>
                          </div> -->
              <div class="row-pricing -anim">
                <div class="pricing-context">Secure login</div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
              </div>
              <div class="row-pricing -anim">
                <div class="pricing-context">24/7 access</div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
              </div>
              <div class="row-pricing -anim">
                <div class="pricing-context">Delivery & courier access</div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
              </div>
              <div class="row-pricing -anim">
                <div class="pricing-context">
                  Finance terms available (approved applicants only)
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
                <div class="pricing-options">
                  <span class="checkmark yes"></span>
                </div>
              </div>
            </div>
            <!-- .pricing-details -->
          </div>
          <!-- .pricing-group -->
        </div>
        <!-- .pricing-group-wrapper -->
      </div>
      <!-- .container -->
    </section>
    <!-- .pricing -->

    <!-- *ngIf is set to false so this section is hidden until content exists -->
    <section *ngIf="false" id="latest-news" class="latest-news -anim-container">
      <div class="row-news row">
        <div class="heading boxed">
          <div class="inner">
            <h2 class="-anim">
              Recent<br />
              Updates
            </h2>
          </div>
        </div>
        <div class="cards-wrapper hide-scrollbar">
          <button class="glider-prev glider-button"></button>
          <div class="cards-holder">
            <div class="card boxed">
              <div class="inner">
                <a href="#"
                  ><img
                    class="-anim"
                    src="/assets/images/landing/news-image.jpg"
                    height="210"
                    width="326"
                    alt=""
                /></a>
                <h3 class="-anim">News Title Heading</h3>
                <p class="-anim">
                  Browse, buy and get it delivered directly from suppliers such
                  as Tarkett, Interface and more. Bloody easy.
                </p>
                <div class="cta -anim"><a href="#">Read More</a></div>
              </div>
            </div>
            <!-- .card -->
            <div class="card boxed">
              <div class="inner">
                <a href="#"
                  ><img
                    class="-anim"
                    src="/assets/images/landing/news-image.jpg"
                    height="210"
                    width="326"
                    alt=""
                /></a>
                <h3 class="-anim">News Title Heading</h3>
                <p class="-anim">
                  Browse, buy and get it delivered directly from suppliers such
                  as Tarkett, Interface and more. Bloody easy.
                </p>
                <div class="cta -anim"><a href="#">Read More</a></div>
              </div>
            </div>
            <!-- .card -->
            <div class="card boxed">
              <div class="inner">
                <a href="#"
                  ><img
                    class="-anim"
                    src="/assets/images/landing/news-image.jpg"
                    height="210"
                    width="326"
                    alt=""
                /></a>
                <h3 class="-anim">News Title Heading</h3>
                <p class="-anim">
                  Browse, buy and get it delivered directly from suppliers such
                  as Tarkett, Interface and more. Bloody easy.
                </p>
                <div class="cta -anim"><a href="#">Read More</a></div>
              </div>
            </div>
            <!-- .card -->
            <div class="card boxed">
              <div class="inner">
                <a href="#"
                  ><img
                    class="-anim"
                    src="/assets/images/landing/news-image.jpg"
                    height="210"
                    width="326"
                    alt=""
                /></a>
                <h3 class="-anim">News Title Heading</h3>
                <p class="-anim">
                  Browse, buy and get it delivered directly from suppliers such
                  as Tarkett, Interface and more. Bloody easy.
                </p>
                <div class="cta -anim"><a href="#">Read More</a></div>
              </div>
            </div>
            <!-- .card -->
            <div class="card boxed">
              <div class="inner">
                <a href="#"
                  ><img
                    class="-anim"
                    src="/assets/images/landing/news-image.jpg"
                    height="210"
                    width="326"
                    alt=""
                /></a>
                <h3 class="-anim">News Title Heading</h3>
                <p class="-anim">
                  Browse, buy and get it delivered directly from suppliers such
                  as Tarkett, Interface and more. Bloody easy.
                </p>
                <div class="cta -anim"><a href="#">Read More</a></div>
              </div>
            </div>
            <!-- .card -->
          </div>
          <button class="glider-next glider-button"></button>
        </div>
        <!-- .cards-wrapper -->
      </div>
    </section>

    <section id="footer" class="section-footer color-white reveal-blocks">
      <div class="container flex">
        <div class="contact">
          <h2 class="h1">Any questions?</h2>
          <h3>
            Please email us at
            <a
              href="mailto:team@flrhub.com.au"
              target="_blank"
              gaEvent="contact_email_landing"
              gaCategory="click_event"
              >team@flrhub.com.au</a
            >
          </h3>
        </div>
        <div class="contact-address">
            PO BOX 555, The Gap, Brisbane 4061 QLD<br><br>
            <b>Visits only by appointment</b><br>
            Adelaide - 4/59 Pennington Terrace North Adelaide 5006, Australia<br>
            Brisbane - 12B/22 Baildon Street Kangaroo Point 4169, Australia<br>
        </div>
      </div>
      <div class="copyright-lines">
        <div class="inner">
          <div class="copy">
            ABN: 68 649 251 543<br>
            &copy;2001-<span id="current-year">2022</span> All Rights Reserved.
            FLRHUB®
          </div>
          <nav class="links">
            <ul class="ul-reset">
              <li><a href="/privacy">Privacy</a></li>
              <li><a href="/terms">Terms</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </div>
  <!-- .page-content -->
</div>
<!-- .page-wrapper -->

<div id="scrollspy">
  <div class="cursor"></div>
</div>

<div id="video-player">
  <div class="shader close-vid"></div>
  <div class="video-container close-vid">
    <button class="video-close close close-vid"></button>
    <div class="video-holder">
      <div class="video-container video-container--inner">
        <div class="video-scaler">
          <div class="video-inner"></div>
        </div>
      </div>
    </div>
  </div>
</div>
