
<div class="page-wrapper">

  <header id="masthead">
      <div class="inner boxed center-logo">
          <div class="site-logo"><a href="/"><img src="/assets/images/FLRHUB-Logo_UPDATED.svg" width="165" height="50" alt="Flrhub Logo"/></a></div>
          <!--
            Dont need on terms and privacy aye?
          <nav class="primary">
              <ul class="ul-reset">
                  <li><a href="/#features">Features</a></li>
                  <li><a href="/#pricing">Pricing</a></li>
              </ul>
          </nav>
          <nav class="secondary">
              <ul class="ul-reset">
                  <li><a href="/login/">Log In</a></li>
                  <li><a href="/#pricing" class="primary-button">Get Started</a></li>
              </ul>
          </nav>
          -->
      </div>
  </header><!-- #masthead -->

  <div class="page-content">



      <section id="entry-content" class="entry-content boxed">
          <div class="container">
              <div class="heading">
                  <h1>Estimating Terms and Conditions</h1>
                  <div class="context hierarchy-list">
                      <p>These Estimating Terms and Conditions (“Terms”), as amended or replaced from time to time, apply to the provision of the Estimating Services by FLRHUB to the Customer, or to any third party on the Customer’s behalf (“Customer” or “You”).</p>

<ol>
  <li>CONTRACT
      <ol>
          <li>These Terms constitute the entire agreement between FLRHUB and the Customer in relation to the Estimating Services. All prior negotiations, representations, understandings, arrangements and agreements (whether oral and/or in writing) are superseded by these Terms.</li>
          <li>You shall be deemed to have accepted these Terms and to have entered into a Contract when:
            <div class="alpha-list">
                <div class="list">You instruct FLRHUB to proceed to supply the Estimating Services; or</div>
                <div class="list">You pay the Fees.</div>
            </div>
          </li>
      </ol>
  </li>
  <li>PAYMENT
      <ol>
          <li>You must pay the Fees to FLRHUB in advance of FLRHUB providing the Estimating Services. To avoid doubt, FLRHUB will not commence the provision of the Estimating Services until the Fees have been received by FLRHUB in cleared funds.</li>
          <li>Payment of the Fees must be made by credit card only.</li>
          <li>Unless otherwise stated:
              <div class="alpha-list">
                  <div class="list">the Fees for the Estimating Services and any other amount payable under the Contract shall be exclusive of any tax payable pursuant to A New Tax System (Goods and Estimating Services Tax) Act 1999 (Cth) (“GST”) except where the amount is expressed to be inclusive of GST; and</div>
                  <div class="list">the Customer will be required to pay to FLRHUB an amount equal to the GST (which will be notified to You on the Site) in addition to (and at the same time as) the Fees and any relevant other amount.</div>
              </div>
          </li>
      </ol>
  </li>
  <li>DUTIES
      <ol>
          <li>Your duties are:
              <div class="alpha-list">
                  <div class="list">to ensure the Information is full and complete and contains all information and details upon which you require an Estimation.</div>
                  <div class="list">to make reasonable efforts to be available to give FLRHUB instructions in relation to the Estimating Services to be carried out, including replying promptly to any emails and other correspondence FLRHUB sends requesting further information from You; and</div>
                  <div class="list">to keep FLRHUB advised at all times of Your contact details. FLRHUB will not be responsible for your Losses if FLRHUB cannot contact You or if You do not give FLRHUB instructions and information when FLRHUB needs them.</div>
              </div>
          </li>
      </ol>
  </li>
  <li>ACKNOWLEDGEMENTS
      <ol>
          <li>You acknowledge and agree that:
              <div class="alpha-list">
                <div class="list">if You require FLRHUB to review any Estimation or provide responses to You in relation to questions regarding the Estimation, then additional costs will apply;</div>
                <div class="list">while the Estimation is provided by an experienced estimator(s), due to the nature of estimating and, in particular, differing methods of estimating, there is always an inherent risk of inaccuracy. Also, estimates are, by their nature, subjective and based on estimating and experience needs at the time (and markets can change for various reasons). It is therefore Your responsibility to ensure that all details in the Estimation, including quantity estimates, are acceptable to You before You use and rely on the Estimation, and that the Estimation should not be the only source of information on which You rely;</div>
                <div class="list">the Estimation may be relied on as “estimates” which means an approximate calculation which may be lower or higher than the actual required. The estimates contained in the Estimation may be relied on as long as it is deemed as, and accepted by You as, an “estimate”. However, FLRHUB strongly recommends that You seek or consult a registered quantity surveyor to get a more realistic and applicable material quantity estimate for Your project;</div>
                <div class="list">the Estimation is provided for Your use only and You may not encourage or allow any other person to rely on it. FLRHUB shall not be liable for any Losses You or anyone else suffers as a result of relying on the Estimation. This includes not being liable for any loss of profit, loss of bargain, loss of capital through over-payment or under-sale, or for any indirect, special or Consequential Loss;</div>
                <div class="list">the Estimation must not be used for any purpose other than for the project to which the Estimating Services relate to; and</div>
                <div class="list">the Estimation is confidential and remains the intellectual property of FLRHUB.</div>
              </div>
          </li>
      </ol>
  </li>
  <li>DELIVERY
      <ol>
        <li>Any period or date for delivery of Estimating Services specified is intended as an estimate only and is not a contractual commitment on the part of FLRHUB.</li>
        <li>FLRHUB will make all reasonable efforts to arrange for the delivery of Estimating Services to You within a reasonable time but if, for any reason, including negligence by FLRHUB its servants, employees, agents, contractors or service providers, delivery of the Estimating Services is or will be delayed, FLRHUB will not be responsible for any Loss sustained by You or any other person, firm or entity by reason of any such delay, including direct or Consequential Loss, loss of revenue, pure economic loss, loss of opportunity or the like.</li>
        <li>If the cause of any delay was caused by You or under Your control (or anyone employed by or acting on Your behalf, including but not limited to a nominee), then FLRHUB will, at its sole discretion, be entitled to increase the Fees for the Estimating Services.</li>
      </ol>
  </li>
  <li>SUB-CONTRACTORS
      <ol>
          <li>You acknowledge and agree that FLRHUB may engage suitably experienced sub-contractors to assist in the performance of the Estimating Services. You must not (without our prior approval) communicate directly with FLRHUB’s sub-contractors unless that occurs through emails directed to FLRHUB or through the Site.</li>
      </ol>
  </li>
  <li>TERMINATION
      <ol>
        <li>FLRHUB may in its absolute discretion terminate the Contract at any time by giving You notice in writing (“Termination Notice”).</li>
        <li>Upon receipt of the Termination Notice, You must immediately pay all amounts outstanding and payable by You to FLRHUB pursuant to the Contract.</li>
        <li>If FLRHUB terminates the Contract pursuant to clause 7.1 prior to the supply of any Estimating Services, then it will refund any Fees paid by You relating to those Estimating Services.</li>
      </ol>
  </li>
  <li>LIMITATION OF LIABILITY
      <ol>
        <li>The Estimation shall be produced by experienced estimator(s) and is provided to You in good faith and is believed to be accurate, appropriate and reliable at the time it is provided to You, however, FLRHUB gives no warranty in relation to the Estimating Services provided and You expressly agree that use of, and reliance upon, the Estimating Services (including the Estimation) is at Your own risk.</li>
        <li>To the maximum extent permitted by law:
            <div class="alpha-list">
                <div class="list">except as expressly provided to the contrary in the Contract, all representations, warranties, terms and conditions in relation to the Estimating Services and these Terms (whether express, implied, statutory or otherwise) are hereby excluded; and</div>
                <div class="list">without limiting the generality of the preceding clause, FLRHUB is not liable or responsible in any way to You or any other person for any Loss arising from, related to, or in any way connected with the Estimating Services or the provision of the Estimating Services, and You will not authorise or represent to anyone that the Estimation can be relied upon by any third party. The total liability of FLRHUB for Loss of arising out of, related to, or in any way connected with, the provision of the Estimating Services, whether in tort, contract or any other cause of action, is limited to, at the sole and absolute discretion of FLRHUB, the provision of the Estimating Services again or refund of the Fees paid by You in connection with the Estimating Services.</div>
            </div>
        </li>
        <li>Where any law implies in the Contract any term, condition or warranty and such law voids or prohibits provisions in a contract excluding or modifying the application of, or exercise of, or liability under, such term, condition or warranty, such term, condition or warranty will be deemed to be included in these Terms. However, the liability of FLRHUB for any breach of such term, condition or warranty will be limited at the sole and absolute option of FLRHUB to any one or more of the following:
            <div class="alpha-list">
                <div class="list">the provision of the Estimating Services again; or</div>
                <div class="list">refund of the Fees paid by You in connection with the Estimating Services.</div>
            </div>
        </li>
        <li>Any claim by the Customer in respect of defective Estimating Services must be confirmed by email to: estimating@flrhub.com.au within 7 days after the date of delivery of the Estimating Services.</li>
        <li>You agree to indemnify FLRHUB and holds it harmless against any and all actions, claims, proceedings, costs, Losses, and liabilities (including legal fees) suffered or incurred by FLRHUB arising out of, in connection with or resulting from the Estimating Services or the provision or use of the Estimating Services, including any use and reliance on the Estimation, whether direct or Consequential Loss.</li>
        <li>Subject to Clause 8.3 above, FLRHUB is not liable for any Consequential Loss however caused, suffered or incurred by You, or any party claiming through You, in connection with the Contract.</li>
      </ol>
  </li>
  <li>CONFIDENTIALITY & INTELLECTUAL PROPERTY
      <ol>
        <li>Provided You have complied with Your obligations to pay our Fees, FLRHUB grants to You a non-exclusive, irrevocable, licence to use the Estimation produced by FLRHUB in the performance of the Estimating Services.</li>
        <li>For clarity, the Estimation remains FLRHUB’s property and nothing in the Contract grants to You any interest in that Estimation apart from the licence granted in Clause 9.1.</li>
        <li>These Terms do not pass any interest to You in the proprietary information or intellectual property of FLRHUB, and You agree that You will not disclose any such information or intellectual property or use it for purposes outside of these Terms and the provision of the Estimating Services.</li>
      </ol>
  </li>
  <li>INDEMNITY
      <ol>
        <li>You agree to indemnify and keep indemnified and hold FLRHUB and its officers, directors, employees, agents, successor and assignees, harmless from and against all Loss incurred or suffered by FLRHUB, and from and against all actions, proceedings, claims or demands made against FLRHUB, arising from, but no limited to, one or more of the following:
        <div class="alpha-list">
            <div class="list">the provision of Estimating Services, the Estimation and/or the Contract;</div>
            <div class="list">any breach of the Contract (including these Terms) by You.</div>
        </div>
        </li>
        <li>This clause 10 remains in force after the termination or expiry of the Contract.</li>
      </ol>
  </li>
  <li>INFORMATION & WARRANTIES
      <ol>
        <li>You are responsible for the completeness and accuracy of the Information that You supply to FLRHUB. FLRHUB will not independently verify or assess the accuracy or veracity of Information and will not be liable for any Loss resulting from the Information, including, without limitation, inaccurate, incomplete or defective Information.</li>
        <li>You warrant that You have obtained all necessary third-party consents to the supply of the Estimating Services by FLRHUB to You, including consents to the use of intellectual property held by third parties.</li>
        <li>You acknowledge and agree that all Information You provide to FLRHUB is accurate and complete and that FLRHUB will, without independent verification, rely on it for the purpose of providing the Estimating Services. FLRHUB shall not assume any responsibility or have any liability for such Information. You warrant that the Information provided by You to FLRHUB is correct, accurate, full and complete and contains all information and details upon which you require an Estimation. FLRHUB takes no responsibility for any Loss arising if the Information is in any way incomplete, inaccurate or contains errors. You take full responsibility for, and shall indemnify FLRHUB in respect of, all Losses that arise (and incurred by either Party to the Contract) if the Information is incomplete, inaccurate or contains errors.</li>
        <li>To the extent permitted by law, FLRHUB disclaims all responsibility for Your failure to inform FLRHUB of any changes to any Information which impact, or may impact, upon the Estimating Services. </li>
        <li>You represent that You have the right to supply such Information to FLRHUB and that the supply of such Information by You and its use by FLRHUB for the purposes of the provision of the Estimating Services will not infringe any rights held by any third party (including Intellectual Property Rights), involve the unauthorised use of confidential information belonging to a third party, or result in the breach by You or FLRHIUB of any law, regulation, fiduciary duty, Intellectual Property Right or the Contract.</li>
      </ol>
  </li>
  <li>FORCE MAJEURE
      <ol>
          <li>Neither Party will be liable to the other Party for any delay or failure to fulfil obligations under the Contract to the extent that such delay or failure arises from unforeseen causes beyond the first-mentioned Party’s reasonable control, having taken reasonable precautions, including fire, floods, acts of God, terrorism, strikes, lock out, war, riot or any governmental act or regulation. </li>
      </ol>
  </li>
  <li>NOTICES AND ELECTRONIC COMMUNICATION
      <ol>
          <li>Any notice required under the Contract may be given by any Party, including any director or authorised person of that Party. Any notice may be given at that Party’s registered address or other address stipulated in any notice provided by that Party or as notified in writing for the purposes of the Estimating Services. Without limitation, this includes any electronic address notified to the other Party.</li>
          <li>The Customer agrees that:
              <div class="alpha-list">
                  <div class="list">if the Customer’s signature or execution is required, the requirement is taken to have been met by an electronic communication; and </div>
                  <div class="list">if FLRHUB is required to produce a document that is in the form of paper, the requirement is taken to have been met by an electronic communication.</div>
              </div>
          </li>
      </ol>
  </li>
  <li>GOVERNING LAW
      <ol>
          <li>The Contract is governed by the laws of South Australia and each Party irrevocably and unconditionally submits to the non-exclusive jurisdiction of courts exercising jurisdiction there.</li>
      </ol>
  </li>
  <li>VARIATION
      <ol>
          <li>The Parties can vary the Contract only if the variation is in writing and signed by each of the Parties. </li>
      </ol>
  </li>
  <li>RESTRICTION ON ASSIGNMENT
      <ol>
          <li>A Party must obtain the prior written consent of the other Party before it assigns, transfers or otherwise disposes of a right or obligation under the Contract. </li>
      </ol>
  </li>
  <li>SEVERANCE
      <ol>
          <li>If any provision of the Contract is invalid, illegal or unenforceable, that provision must be severed from and ignored in the interpretation of the Contract to the minimum extent necessary and to the intent that the remaining provisions of the Contract remain in full force and effect.</li>
      </ol>
  </li>
  <li>WAIVER
      <ol>
        <li>A Party's failure, partial failure or delay in exercising a right relating to the Contract is not a waiver of that right.</li>
        <li>Any waiver granted hereunder must be in writing and shall be valid only in the specific instance in which it is given.</li>
      </ol>
  </li>
  <li>SURVIVAL
      <ol>
          <li>All representations and warranties in the Contract will survive the execution and delivery of the Contract and the completion of transactions contemplated by it. </li>
      </ol>
  </li>
  <li>FURTHER ASSURANCE
      <ol>
          <li>Each Party at its own expense must do everything necessary to give full effect to the Contract. </li>
      </ol>
  </li>
  <li>NO MERGER
      <ol>
          <li>A Party's rights and obligations do not merge on the execution or completion of the Contract or the completion of a transaction under the Contract. </li>
      </ol>
  </li>
  <li>NO REPRESENTATIONS
      <ol>
          <li>Subject to the express provisions in the Contract:
            <div class="alpha-list">
                <div class="list">neither a Party nor its representative has made any representation to another Party to induce that other Party to enter into the Contract; and </div>
                <div class="list">neither a Party nor a person acting on a Party's behalf was induced to enter into the Contract by relying on a representation that another Party has made.</div>
            </div>
          </li>
      </ol>
  </li>
  <li>NATURE OF RELATIONSHIP
    <ol>
        <li>The Parties acknowledge and agree that the relationship between them is that of principal and independent contractor. Nothing in the Contract constitutes, nor will it be deemed to constitute, a relationship of agency between the Parties.</li>
        <li>Neither Party has any authority to incur, and must not incur, any obligation on the part of the other Party, except as expressly stated under the Contract or with, and to the extent of, the prior written authority of the other Party. </li>
    </ol>
</li>
  <li>DEFINITIONS
      <ol>
          <li>In these Terms unless the contrary intention appears:
              <div class="alpha-list">
                  <div class="list">“Contract” means the contract for the supply of Estimating Services by FLRHUB to You, constituted by these Terms.</div>
                  <div class="list">“Consequential Loss” means consequential loss and:
                      <div class="roman-list">
                        <div class="list">indirect loss;</div>
                        <div class="list">loss of revenues;</div>
                        <div class="list">loss of reputation;</div>
                        <div class="list">loss of profits;</div>
                        <div class="list">loss of actual or anticipated savings;</div>
                        <div class="list">loss of capital through over-payment or under-sale;</div>
                        <div class="list">lost opportunities, including opportunities to enter into arrangements with third parties; and</div>
                        <div class="list">loss or corruption of data.</div>
                      </div>
                  </div>
                  <div class="list">“Estimation” means the material(s) quantity estimation documentation (including quantity spreadsheet and plan(s)) prepared by FLRHUB as a part of the Estimating Services.</div>
                  <div class="list">“Estimating Services” means the estimation of quantities of materials as requested by You via the Site, and the provision of the associated Estimation by FLRHUB.</div>
                  <div class="list">“Fees” means the amounts payable by You to FLRHUB for the Estimating Services, including all fees, disbursements and GST as notified to You by FLRHUB on the Site. Fees exclude GST.</div>
                  <div class="list">“FLRHUB” means FLRHUB Pty Ltd and any and all of its “Related Bodies Corporate” as such term is defined in the Corporations Act 2001 (Cth).</div>
                  <div class="list">“electronic communication” means a communication of information in the form of data, text or images by guided or unguided electromagnetic energy and includes a scanned image or any other form of digital storage.</div>
                  <div class="list">“Information” means all plans, specifications, measurements, descriptions of materials, images and diagrams, pricing, layouts, instructions, and all other data and information whatsoever provided by You for the purpose of FLRHUB providing the Estimating Services.</div>
                  <div class="list">“Intellectual Property Rights” means all rights and interests throughout the world vesting or otherwise in relation to industrial or intellectual property protectable under law or otherwise, whether registered, unregistered or registrable, and whether now existing or that come into existence in the future, including the following and anything derived, developed or prepared from the following:
                      <div class="roman-list">
                        <div class="list">any patent, trade mark, copyright (including future copyright), moral right, design, plant breeder’s rights, circuit layout rights or any other corresponding property or right under the laws of any jurisdiction; </div>
                        <div class="list">rights in respect of an invention, discovery, trade secret, know-how, concept, idea, methodology, information, data, algorithm or formula; </div>
                        <div class="list">any right to apply for grant or registration of intellectual property or intellectual property rights; and </div>
                        <div class="list">all renewals and extensions and all similar or equivalent rights or forms of protection in relation to intellectual property or intellectual property rights. </div>
                      </div>
                  </div>
                  <div class="list">“Loss” means any and all loss, liability, damage, cost, and expense (including legal costs on a solicitor-client basis), whether direct or Consequential Loss, and whether present or future. </div>
                  <div class="list">“Party” means a party to the Contract, being FLRHUB or You, as the context requires. </div>
                  <div class="list">“Site” means the FLRHUB website.</div>
                  <div class="list">The singular includes the plural and the converse.</div>
                  <div class="list">Any reference in the Contract to the Customer or You also includes its/Your respective successors or permitted assigns. If the Customer constitutes more than one person or entity, the Contract bind each of them jointly and severally.</div>
              </div>
          </li>
      </ol>
  </li>

</ol>


                  </div>
              </div>
          </div>
      </section><!-- .entry content -->





      <section id="footer" class="section-footer color-white reveal-blocks">
          <div class="container">
              <h2 class="h1">Any questions?</h2>
              <h3>Please email us at <a href="mailto:team@flrhub.com.au" target="_blank">team@flrhub.com.au</a></h3>
          </div>
          <div class="copyright-lines">
              <div class="inner">
                  <div class="copy">&copy;2001-<span id="current-year">2022</span> All Rights Reserved. FLRHUB®</div>
                  <nav class="links">
                      <ul class="ul-reset">
                          <li><a href="/privacy/">Privacy</a></li>
                          <li><a href="/terms/">Terms</a></li>
                      </ul>
                  </nav>
              </div>
          </div>
      </section>



  </div><!-- .page-content -->
</div><!-- .page-wrapper -->
