<ng-template #steps>
    <div class="steps">
        <div class="step active">
            <div class="circle">1</div>
            <div class="text">Shipping</div>
        </div>
        <div class="step">
            <div class="circle">2</div>
            <div class="text">Payment</div>
        </div>
        <div class="step">
            <div class="circle">3</div>
            <div class="text">Review</div>
        </div>
    </div>
</ng-template>

<ng-template #products>
    <div *ngFor="let parcelKey of keysForObject(order.shippingPackages); let supplierIndex = index" class="brand-group">
        <div class="brand-title">Parcel {{supplierIndex+1}} - {{parcelKey}}</div>
        <div class="cart-item" *ngFor="let cartItem of order.cart; let i = index;">
            <img class="cart-item-image" [src]="cartItem.product.thumbnailImageUrl">
            <div class="item-container">
                <div class="item-name-row">
                    <div class="item-name">{{cartItem.product.productCollection}}</div>
                    <div class="item-price">{{productClass.calculateRetailCost(cartItem.product,cartItem.quantity)}}<span
                        *ngIf="cartItem.product.unitType !== 'Quantity'">/{{unitTypeToMeasure(cartItem.product.unitType)}}</span></div>
                </div>
                <p class="category" [innerHTML]="cartItem.product.brand + (cartItem.product.subBrand ? ' - '+cartItem.product.subBrand : '') +' | '+ (cartItem.product.productCategory) | safeHtml"></p>
                <p class="design">{{cartItem.product.productDesign}}{{cartItem.product.variations && cartItem.product.variations['Size'] ? ' - '+cartItem.product.variations['Size'].option : ''}}</p>
                <div class="total-row">
                    <div>Total: {{cartItem.quantity}}{{cartItem.product.unitType === 'Each' ? ' Units' : cartItem.product.unitType === 'LM' ? 'LM' : 'm²'}}</div>
                    <div class="total">{{cartItem.total}}</div>
                </div>
                <div *ngFor="let lineItem of cartItem.lineItems" class="total-row">
                    <div>{{lineItem.product.productCollection}} {{lineItem.product.color}} - {{lineItem.quantity}}</div>
                    <div class="total">{{lineItem.total}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="cart-total-container">
        <div class="title">Cart Total</div>
        <div class="total-rows">
            <div class="total-item">Subtotal:</div>
            <div class="total-cost">{{order.getSubTotal()}}</div>
        </div>
        <div class="total-rows" *ngIf="order.getBulkDiscount()">
            <div class="total-item">Discount (Bulk order):</div>
            <div class="total-cost">-{{this.order.getBulkDiscount()}}</div>
        </div>
        <div class="total-rows" *ngIf="this.order.discountCodeAmount">
            <div class="total-item">Discount ({{this.order.discountCode}}):</div>
            <div class="total-cost">-{{this.order.discountCodeAmount}}</div>
        </div>
        <div class="total-rows">
            <div class="total-item"> Shipping Total:</div>
            <div class="total-cost shipping-cost">{{this.isRegional && this.selectedDeliveryOption !== 'pickup' ? "Please contact us for a quote" : shippingTotal}}</div>
        </div>
        <div class="total-rows">
            <div class="total-item">GST:</div>
            <div class="total-cost">{{order.getGst()}}</div>
        </div>
        <div class="total-rows">
            <div class="total-item">Total:</div>
            <div class="total-cost">{{order.getTotalWithGst(this.isRegional ? "0" : shippingTotal)}}</div>
        </div>
    </div>
</ng-template>

<div class="page-container">
    <img class="logo hide-phone" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg">

    <div class="content-container">
        <div *ngIf="loading" class="page-loading">Loading...</div>
        <div class="header show-phone">
            <div class="small-button back-button" (click)="back()">Back</div>
            <div class="header-title">Check Out</div>
        </div>
        <div *ngIf="!loading" class="content">
            <div class="column grey-bg hide-phone">
                <div class="small-button back-button" (click)="back()">Back</div>
                <div class="title">Check Out</div>
                <ng-container *ngTemplateOutlet="steps"></ng-container>
                <ng-container *ngTemplateOutlet="products"></ng-container>
            </div>
            <div class="column">
                <div class="show-phone">
                    <ng-container *ngTemplateOutlet="steps"></ng-container>
                </div>
                <div class="title">Delivery to</div>
                <div class="input-field" [ngClass]="{error: errors.shippingName}">
                    <label for="shippingName">Name</label>
                    <input id="shippingName" [(ngModel)]="order.shippingName" (ngModelChange)="validate()"
                        placeholder="Type here..." type="text">
                </div>
                <div class="input-field" [ngClass]="{error: errors.shippingPhone}">
                    <label for="shippingPhone">Phone number</label>
                    <input id="shippingPhone" [(ngModel)]="order.shippingPhone" (ngModelChange)="validate()"
                        placeholder="Type here..." type="tel" numbersOnly="true" maxlength="10">
                </div>
                <div class="input-field" [ngClass]="{error: errors.shippingAddress}">
                    <label for="shippingAddress">Delivery address</label>
                    <!-- <input id="shippingAddress" [(ngModel)]="order.shippingAddress" (ngModelChange)="validate()"
                        placeholder="Type here..." type="text"> -->
                    <input id="shippingAddress" ngx-google-places-autocomplete [options]='options'
                        [(ngModel)]="order.shippingAddress" (ngModelChange)="validate()"
                        (onAddressChange)="handleAddressChange($event)" placeholder="Type here..." type="text" autocomplete="off" />
                </div>
                <div class="input-field" [ngClass]="{error: errors.shippingNotes}">
                    <label for="shippingNotes">Delivery notes</label>
                    <input id="shippingNotes" [(ngModel)]="order.shippingNotes" (ngModelChange)="validate()"
                        placeholder="Type here..." type="text">
                </div>
                <div class="input-field" [ngClass]="{error: errors.deliveryDate}">
                    <label for="deliveryDate">Delivery date</label>
                    <input onfocus="this.showPicker()" [(ngModel)]="order.deliveryDate" (ngModelChange)="dateChanged()" [min]="currentDate" id="dispatchDate" type="date">
                </div>
                <div class="show-phone">
                    <ng-container *ngTemplateOutlet="products"></ng-container>
                </div>

                <div class="title">Delivery and handling</div>
                <div class="delivery-content" >
                                    <div class="parcels">
                                        <div class="brand-title">Select Delivery Option</div>

                        <p *ngIf="!this.postcode">Delivery options will be available once a delivery address is entered</p>
                                        <!-- Loop through predefined delivery options -->
                                        <div *ngIf="this.postcode" class="select-boxes">
                                            <button class="select-box" *ngFor="let option of deliveryOptions"
                                                [ngClass]="{ selected: selectedDeliveryOption === option.value }"
                                                (click)="selectDeliveryOption(option.value)" [disabled]="!option.isRegional === isRegional && option.value !== 'pickup'">
                                                <div class="delivery-content">
                                                    <div class="select-description">
                                                        <div class="title">{{ option.title }}</div>
                                                        <div class="price">{{ option.price }}</div>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>

                                        <!-- Conditional Site-Specific Fields -->
                                        <div *ngIf="showSiteSpecificFields" class="site-specific-fields">
                                            <div class="input-field" [ngClass]="{ error: errors['siteContact'] }">
                                                <label for="siteContact">Site Contact</label>
                                                <input id="siteContact" [(ngModel)]="siteContact" (ngModelChange)="validate()" type="text"
                                                    placeholder="Type here..." />
                                            </div>
                                            <div class="input-field" [ngClass]="{ error: errors['siteContactNumber'] }">
                                                <label for="siteContactNumber">Site Contact Number</label>
                                                <input id="siteContactNumber" [(ngModel)]="siteContactNumber" (ngModelChange)="validate()" type="tel"
                                                    maxlength="10" placeholder="Type here..." />
                                            </div>
                                        </div>
                                    </div>


                                    </div>

                <div *ngFor="let parcelKey of keysForObject(order.shippingPackages); let i = index" class="parcels">
                    <div class="brand-title">Parcel {{i+1}} - {{parcelKey}}</div>
                    <div class="select-boxes" [ngClass]="{error: errors['deliveryOption-' + parcelKey]}">
                        <p *ngIf="order.shippingPackages[parcelKey].deliveryOptions.length <= 0">Delivery options will be available once a delivery address is entered</p>
                        <div class="select-box"
                            *ngFor="let shippingOption of order.shippingPackages[parcelKey].deliveryOptions"
                            [ngClass]="{selected: order.shippingPackages[parcelKey].deliveryOption.option === shippingOption.title}">
                                                     <div class="delivery-fields"
                                *ngIf="order.shippingPackages[parcelKey].deliveryOption.option === shippingOption.title && (order.shippingPackages[parcelKey].deliveryOption.option === 'Site Delivery' || order.shippingPackages[parcelKey].deliveryOption.option == 'Site Delivery (Quote Required)')">
                                <div class="site-contact-container">
                                  <div class="input-field" [ngClass]="{error: errors['siteContact-' + parcelKey]}">
                                    <label for="siteContact">Site Contact</label>
                                    <input id="siteContact" [(ngModel)]="order.shippingPackages[parcelKey].siteContact" (ngModelChange)="validate()" type="text" placeholder="Type here...">
                                  </div>
                                  <div class="input-field" [ngClass]="{error: errors['siteContactNumber-' + parcelKey]}">
                                    <label for="siteContactNumber">Site Contact Number</label>
                                    <input id="siteContactNumber" [(ngModel)]="order.shippingPackages[parcelKey].siteContactNumber" (ngModelChange)="validate()" type="tel" numbersOnly="true" maxlength="10" placeholder="Type here...">
                                  </div>
                                </div>
                                <div class="site-business-container">
                                  <div class="input-field" [ngClass]="{error: errors['businessName-' + parcelKey]}">
                                    <label for="businessName">Business Name</label>
                                    <input id="businessName" [(ngModel)]="order.shippingPackages[parcelKey].businessName" (ngModelChange)="validate()" type="text" placeholder="Type here...">
                                  </div>
                                  <div class="input-field" [ngClass]="{error: errors['customerPoRef-' + parcelKey]}">
                                    <label for="customerPoRef">Customer PO Ref</label>
                                    <input id="customerPoRef" [(ngModel)]="order.shippingPackages[parcelKey].customerPoRef" (ngModelChange)="validate()" type="text" placeholder="Type here...">
                                  </div>
                                </div>
                                <div class="site-times-container">
                                    <div class="input-field" [ngClass]="{error: errors['siteOpenTime-' + parcelKey]}">
                                        <label for="siteOpenTime">Site Opening Time</label>
                                        <select id="siteOpenTime" [(ngModel)]="order.shippingPackages[parcelKey].deliverySiteOpeningTime"
                                            name="siteOpenTime">
                                            <option value="" disabled selected hidden>Pick a site opening time...
                                            </option>
                                            <option value="6am">6am</option>
                                            <option value="7am">7am</option>
                                            <option value="8am">8am</option>
                                            <option value="9am">9am</option>
                                            <option value="10am">10am</option>
                                            <option value="11am">11am</option>
                                            <option value="12pm">12pm</option>
                                            <option value="1pm">1pm</option>
                                            <option value="2pm">2pm</option>
                                            <option value="3pm">3pm</option>
                                        </select>
                                    </div>
                                    <div class="input-field" [ngClass]="{error: errors['deliveryHours-' + parcelKey]}">
                                        <label for="siteCloseTime">Site Closing Time</label>
                                        <select id="siteCloseTime" [(ngModel)]="order.shippingPackages[parcelKey].deliverySiteClosingTime"
                                            name="siteCloseTime">
                                            <option value="" disabled selected hidden>Pick a site closing time...
                                            </option>
                                            <option value="12pm">12pm</option>
                                            <option value="1pm">1pm</option>
                                            <option value="2pm">2pm</option>
                                            <option value="3pm">3pm</option>
                                            <option value="4pm">4pm</option>
                                            <option value="5pm">5pm</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="input-field" [ngClass]="{error: errors['deliveryDropOffLocation-' + parcelKey]}">
                                    <label for="dropOffLocation">Drop-off Location</label>
                                    <select id="dropOffLocation" [(ngModel)]="order.shippingPackages[parcelKey].deliveryDropOffLocation"
                                        name="dropOffLocation">
                                        <option value="" disabled selected hidden>Pick a drop-off location...</option>
                                        <option value="front">Front of building </option>
                                        <option value="rear">Rear of building </option>
                                        <option value="side">Side of building </option>
                                    </select>
                                </div>
                                <div class="check-box-container" (click)="order.shippingPackages[parcelKey].deliveryBuildingSite = (order.shippingPackages[parcelKey].deliveryBuildingSite !== 'Yes' ? 'Yes' : 'No')">
                                    <div class="check-box"
                                        [ngClass]="{'not-selected': order.shippingPackages[parcelKey].deliveryBuildingSite !== 'Yes'}">
                                        <img src="../../../assets/images/check_icon.png">
                                    </div>
                                    <div style="font-size: 13px;">Delivery location is a building site</div>
                                </div>
                                <div class="check-box-container" (click)="order.shippingPackages[parcelKey].deliveryCallBeforeDelivery = (order.shippingPackages[parcelKey].deliveryCallBeforeDelivery !== 'Yes' ? 'Yes' : 'No')">
                                    <div class="check-box"
                                        [ngClass]="{'not-selected': order.shippingPackages[parcelKey].deliveryCallBeforeDelivery !== 'Yes'}">
                                        <img src="../../../assets/images/check_icon.png">
                                    </div>
                                    <div style="font-size: 13px;">Please call 1 hour before delivery</div>
                                </div>
                                <div class="input-field" [ngClass]="{error: errors['deliverySiteSpecificNotes-' + parcelKey]}">
                                    <label for="shippingName">Site specific delivery notes</label>
                                    <input id="shippingName" [(ngModel)]="order.shippingPackages[parcelKey].deliverySiteSpecificNotes"
                                        (ngModelChange)="validate()" placeholder="Type here..." type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a style="font-size: 13px;" href="/delivery" target="_blank">Delivery Policy</a>
                <div class="primary-button" (click)="next()">Proceed to Payment</div>
            </div>
        </div>
    </div>
</div>
