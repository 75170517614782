<div class="page-container">
    <app-menu active="My Hub"></app-menu>
    <div class="content-container">
        <app-header title="My Hub"></app-header>
        <app-my-hub-tabs active="Orders"></app-my-hub-tabs>
        <div id="scrollContent" class="content">
        <!-- <div id="scrollContent" class="content" *ngIf="!loading && orders.length > 0"> -->
            <table class="primary-table">
                <thead class="hide-phone">
                    <th>Order</th>
                    <th>Date</th>
                    <th>Products</th>
                    <th>Payment/Fulfilment Status</th>
                    <th>Delivery Address</th>
                    <th>Total</th>
                </thead>
                <tbody>
                    <tr (click)="viewOrder(order)" *ngFor="let order of orders">
                        <td class="underline">#{{order.orderNumber}}</td>
                        <td class="date">{{order.orderDate }}</td>
                        <td class="product-name-cell">{{order.cart[0].product.productCategory}} -
                            {{order.cart[0].product.productCollection}}
                            -{{order.cart[0].product.productDesign}}
                            <br *ngIf="order.cart.length > 1">
                            <span *ngIf="order.cart.length > 1">{{order.cart[1].product.productCategory}} -
                                {{order.cart[1].product.productCollection}}
                                -{{order.cart[1].product.productDesign}}{{order.cart[1].product.variations && order.cart[1].product.variations['Size'] ? ' - '+order.cart[1].product.variations['Size'].option : ''}}</span>
                        </td>
                        <td>
                            <div *ngIf="order.paymentStatus !== ''" class="tag green">{{order.paymentStatus}}</div>
                            <div [ngClass]="{red: order.fulfilmentStatus == 'ACTION REQUIRED (Order confirmation)'}" class="tag">{{order.fulfilmentStatus}}</div>
                        </td>
                        <td>{{order.shippingAddress}}</td>
                        <td class="bold order-price">{{order.orderTotalWithGst}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <mat-spinner class="loading" *ngIf="loading" diameter="35" strokeWidth="1"></mat-spinner>
        <div *ngIf="!loading && orders.length === 0" class="empty-placeholder">
            <div class="message">No orders are available</div>
            <div class="secondary-button" (click)="goTo('/industry')">Browse Marketplace</div>
        </div>
    </div>
</div>
