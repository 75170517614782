<div class="page-container">
    <img class="logo hide-phone" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg">
    <div class="content-container">
        <div class="header show-phone">
            <div class="small-button back-button" (click)="back()">Back</div>
            <div class="header-title">Check Out</div>
        </div>
        <div class="content">
            <div class="column grey-bg">
                <div class="small-button back-button" (click)="back()">Back</div>
                <div class="title hide-phone">Check Out</div>
                <div class="steps">
                    <div class="step complete">
                        <div class="circle"><img src="../../../assets/images/check_icon.png"></div>
                        <div class="text">Shipping</div>
                    </div>
                    <div class="step complete">
                        <div class="circle"><img src="../../../assets/images/check_icon.png"></div>
                        <div class="text">Payment</div>
                    </div>
                    <div class="step active">
                        <div class="circle">3</div>
                        <div class="text">Review</div>
                    </div>
                </div>
                <div class="cart-item" *ngFor="let cartItem of order.cart; let i = index;">
                    <img class="cart-item-image" [src]="cartItem.product.thumbnailImageUrl">
                    <div class="item-container">
                        <div class="item-name-row">
                            <div class="item-name">{{cartItem.product.productCollection}}</div>
                            <div class="item-price">{{productClass.calculateRetailCost(cartItem.product,cartItem.quantity)}}<span *ngIf="cartItem.product.unitType !== 'Quantity'">/{{unitTypeToMeasure(cartItem.product.unitType)}}</span></div>
                        </div>
                        <p class="category" [innerHTML]="cartItem.product.brand + (cartItem.product.subBrand ? ' - '+cartItem.product.subBrand : '') +' | '+ (cartItem.product.productCategory) | safeHtml"></p>
                        <p class="design">{{cartItem.product.productDesign}}{{cartItem.product.variations && cartItem.product.variations['Size'] ? ' - '+cartItem.product.variations['Size'].option : ''}}</p>
                        <div class="total-row">
                            <div>Total: {{cartItem.quantity}}{{cartItem.product.unitType === 'Each' ? ' Units' : cartItem.product.unitType === 'LM' ? 'LM' :'m²'}}</div>
                            <div class="total">{{cartItem.total}}</div>
                        </div>
                        <div *ngFor="let lineItem of cartItem.lineItems" class="total-row">
                            <div>{{lineItem.product.productCollection}} {{lineItem.product.color}} - {{lineItem.quantity}}</div>
                            <div class="total">{{lineItem.total}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="cart-total-container">
                    <div class="title">Cart Total</div>
                    <div class="total-rows">
                        <div class="total-item">Subtotal:</div>
                        <div class="total-cost">{{order.getSubTotal()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="order.getBulkDiscount()">
                        <div class="total-item">Discount (Bulk order):</div>
                        <div class="total-cost">-{{this.order.getBulkDiscount()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="this.order.discountCodeAmount">
                        <div class="total-item">Discount ({{this.order.discountCode}}):</div>
                        <div class="total-cost">-{{this.order.discountCodeAmount}}</div>
                    </div>
                    <div class="total-rows" *ngIf="order.paymentOption === 'Credit Card Old' || order.paymentOption === 'Bank Transfer Old'">
                        <div class="total-item">Discount* (Payment option)</div>
                        <div class="total-cost">{{'-'+order.getTotalDiscount()}}</div>
                    </div>
                    <div *ngFor="let parcelKey of keysForObject(order.shippingPackages); let supplierIndex = index" class="total-rows">
                        <div class="total-item">Parcel {{supplierIndex+1}} - {{parcelKey}} - {{order.shippingPackages[parcelKey].deliveryOption.option}}:</div>
                        <div class="total-cost shipping-cost">{{order.shippingPackages[parcelKey].deliveryOption.cost}}</div>
                    </div>
                    <div class="total-rows">
                        <div class="total-item">GST:</div>
                        <div class="total-cost">{{order.getGst()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="order.paymentOption === 'Credit Card'">
                      <div class="total-item">1.85% Surcharge:</div>
                      <div class="total-cost">{{ order.getSurchargeAmount() }}</div>
                    </div>
                    <div class="total-rows">
                        <div class="total-item">Total:</div>
                        <div class="total-cost">{{order.getTotalWithGst()}}</div>
                    </div>
                </div>
                <div class="error" style="margin-top: 20px;" *ngIf="errorMessage">{{errorMessage}}</div>
                <div class="pay-row">
                    <img class="lock-icon" src="../../../assets/images/lock_icon.png">
                    <div class="secure-text">
                        <div class="secure-text-1">Secure checkout</div>
                        <div class="secure-text-2">Pay safely with SSL technology.</div>
                    </div>
                    <div class="spacer"></div>
                    <div class="primary-button" [ngClass]="{loading: loading}" (click)="submitOrder()">Submit Order</div>
                </div>
            </div>
        </div>
    </div>
</div>
