import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthHelper } from '../helpers/AuthHelper';
import { DatabaseHelper } from '../helpers/DatabaseHelper';
import { HttpService } from '../http.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class CanActivateAccountActive implements CanActivate {
  constructor(
    private _router: Router,
    private httpService: HttpService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    // return true;
    //return Promise.resolve(true);
    //new Observable();

    return new Promise(async (res, err) => {
      await AuthHelper.instance.isLoggedIn();
      const userResponse = await this.httpService
        .post('/logged-in', {
          responseType: 'json',
          observe: 'response',
        })
        .toPromise();

      console.log('user details', userResponse);

      let user;
      if (userResponse instanceof HttpResponse) user = userResponse.body;
      //check if the users account is pending, cancelled or disabled

      var status = user.status;
      if (status) {
        console.log('status = ' + status);
        console.log('Trying to go to ' + route.url.toString());
        if (status === 'Activated') {
          if (
            route.url.toString().includes('account-pending') ||
            route.url.toString().includes('account-disabled') ||
            route.url.toString().includes('account-cancelled')
          ) {
            this._router.navigate(['/']);
            console.log('return false 1');
            res(false);
          } else {
            console.log('return true 1');
            res(true);
          }
        } else if (status === 'Incomplete') {
          let user = AuthHelper.instance.user;

          //If no details, send there
          if (!user.firstName) {
            this._router.navigate(['sign-up-details']);
          }
          //If no company details, send there
          if (!user.companyName) {
            this._router.navigate(['sign-up-company-details']);
          }
          //If no payment details, send there
          if (!user.simplifyCustomerId) {
            this._router.navigate(['sign-up-payment']);
          }

          //We're not sure what they're missing, but they've past the first step, so send to second step
          this._router.navigate(['sign-up-details']);

          res(true);
          console.log('return false, account incomplete');
        } else if (status === 'Pending') {
          if (route.url.toString().includes('account-pending')) {
            console.log('return true 2');
            res(true);
          } else {
            this._router.navigate(['account-pending']);
            console.log('return true 3');
            res(true);
          }
        } else if (status === 'Disabled') {
          if (route.url.toString().includes('account-disabled')) {
            console.log('return true 4');
            res(true);
          } else {
            this._router.navigate(['account-disabled']);
            console.log('return false 5');
            res(false);
          }
        } else if (status === 'Cancelled') {
          if (route.url.toString().includes('account-cancelled')) {
            console.log('return true 6');
            res(true);
          } else {
            this._router.navigate(['account-cancelled']);
            console.log('return false 7');
            res(false);
          }
        } else {
          if (route.url.toString().includes('account-pending')) {
            console.log('return true 8');
            res(true);
          } else {
            this._router.navigate(['account-pending']);
            console.log('return false 9');
            res(false);
          }
        }
        console.log('return DONE!');
      } else {
        //We don't have status so wait for the user fetching data call back
        DatabaseHelper.instance.dataCallbacks.dataCallback = (val: any) => {
          //We now have the data so call this function again
          res(this.canActivate(route, state));
        };
      }
    })
      .then((val: any) => {
        console.log('returning ' + val);
        return val;
      })
      .finally(() => {
        console.log('all done now ');
      });
  }
}
