<div class="page-container phone-padding-top">
    <div class="show-phone top-logo-container">
        <img class="logo" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg">
    </div>
    <img class="logo hide-phone" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg">
    <div class="inner-container">
        <h1 class="heading">Log In</h1>
        <p class="sub-heading">Need a FLRHUB account? <a href="/sign-up">Sign up here</a></p>
        <div class="input-field">
            <label for="email">Email Address</label>
            <input id="email" [(ngModel)]="email" placeholder="Type here..." type="email">
        </div>
        <div class="input-field password-field">
            <label for="password">Password</label>
            <input id="password" [(ngModel)]="password" placeholder="Type here..." type="password" (keyup.enter)="login()">
        </div>
        <div class="button-link forgot-password-button" href="/forgot-password" (click)="goTo('/forgot-password')">Forgot your password?</div>
        <div *ngIf="errorMessage" style="margin-bottom: 35px;" class="error">{{errorMessage}}</div>
        <div class="primary-button" (click)="login()" [ngClass]="{loading: loading}">Sign In</div>
    </div>
</div>

<img class="background-logo" src="../../../assets/images/bg_logo.png">
