<div class="create-job-pop">
  <div class="head">
    <div class="logo"><img src="../../../assets/images/FLRHUB-Logo_UPDATED.svg" width="135" height="41" class="logo"></div>
    <div class="close secondary-button" (click)="closeCreateJobDialog()">Close</div>
  </div>
  <div class="context">
    <div class="inner">
      <h2>Create a Job</h2>
      <div class="question-item">
        <div class="question">Does your job require a site visit?</div>
        <div class="question-field type-boolean">
          <label>
            <input type="radio" name="need_site_visit" value="Yes" [(ngModel)]="needSiteVisit" (change)="createJobByType(this)">
            <div class="label">Yes</div>
          </label>
          <label>
            <input type="radio" name="need_site_visit" value="No" [(ngModel)]="needSiteVisit" (change)="createJobByType(this)">
            <div class="label">No</div>
          </label>
        </div><!-- type-boolean -->
        <div class="description">(Choose yes if you don’t have plans and require a physical inspection by installers)</div>
      </div>
    </div>
  </div>
</div>
