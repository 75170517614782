<div class="page-container">
    <app-menu active="My Hub"></app-menu>
    <div class="content-container">
        <app-header showBackButton="true" title="My Hub"></app-header>
        <app-my-hub-tabs class="hide-phone" active="Orders"></app-my-hub-tabs>
        <div class="content">
            <div class="small-button hide-phone" (click)="back()">Back</div>
            <div class="order-number-row">
                <div class="order-number">Order #{{order.orderNumber}}</div>
                <!-- <div class="secondary-button small-button" (click)="downloadInvoice()"><img class="icon"
                        src="../../../assets/images/download_icon.png"> Download Invoice</div> -->
                <div class="spacer"></div>
                <div class="secondary-button small-button" gaEvent="order_issue_button" gaCategory="click_event" (click)="issue()">Issue with this order?
                </div>
            </div>
            <div class="order-date">Order placed <b>{{order.orderDate | formattedDate}}</b></div>
            <div class="status-row">
                <div class="status-item">
                    <div class="status-title">Payment/Fulfilment Status</div>
                    <div *ngIf="order.paymentStatus !== ''" class="tag green">{{order.paymentStatus}}</div>
                    <div [ngClass]="{red: order.fulfilmentStatus == 'ACTION REQUIRED (Order confirmation)'}" class="tag">{{order.fulfilmentStatus}}</div>
                </div>
                <div class="status-item">
                    <div class="status-title">Delivery Address</div>
                    <div class="status-description">{{order.shippingAddress}}</div>
                </div>
            </div>
            <table class="primary-table">
                <thead class="hide-phone">
                    <th>Products</th>
                    <th>Payment/Fulfilment Status</th>
                    <th>Price</th>
                    <th>QTY</th>
                    <th>Total</th>
                </thead>
                <tbody *ngFor="let item of excludeAddOns(order.cart)">
                    <tr>
                        <td class="w100">
                            <div class="product-image">
                                <img [src]="item.product.thumbnailImageUrl">
                                <div>{{item.product.productCategory}} - {{item.product.productCollection}} -
                                    {{item.product.productDesign}}{{item.product.variations && item.product.variations['Size'] ? ' - '+item.product.variations['Size'].option : ''}}
                                    <div *ngFor="let addOn of itemsAddOns(item)">{{addOn.product.productCollection}} {{addOn.product.color}}</div>
                                    <div *ngIf="item.stockAvaliablity && order.fulfilmentStatus == 'ACTION REQUIRED (Order confirmation)'" class="small-button save" (click)="selectAnotherProduct(item)">Select replacement product</div>
                                </div>
                            </div>
                        </td>
                        <td class="w100">
                            <div class="tag" [ngClass]="{green: item.stockAvaliablity == 'requestedDate' || item.stockAvaliablity == '7days' || item.stockAvaliablity == '30days' || item.stockAvaliablity == 'future', red: item.stockAvaliablity == 'notAvailable'}">{{getStockAvaliablityDescription(item.stockAvaliablity, item.deliveryDate? item.deliveryDate : item.dispatchDate)}}</div>
                        </td>
                        <td><div class="show-phone header">Price</div>{{productClass.calculateRetailCost(item.product,item.quantity)}}/{{unitTypeToMeasure(item.product.unitType)}}<div *ngFor="let lineItem of item.lineItems">{{lineItem.product.retailPrice}}</div></td>
                        <td>
                            <div class="show-phone header">QTY</div>{{item.quantity}}<div *ngFor="let addOn of itemsAddOns(item)">{{addOn.quantity}} {{addOn.product.unitType}}</div>
                            <div *ngFor="let cut of item.product.cuts; let i = index">{{cut}}LM (Cut {{i+1}})</div>
                        </td>
                        <td class="bold"><div class="show-phone header">Total</div>{{item.total}}<div *ngFor="let addOn of item.addOns">{{addOn.total}}</div></td>
                    </tr>
                    <tr [ngClass]="{'hidden': item.batches && item.batches.length <= 1}" class="small" *ngFor="let batch of item.batches; let i = index;">
                        <td colspan="1">
                        </td>
                        <td [ngClass]="{'complete': batch.batchSize > 0}" colspan="2">
                            <div style="display: flex;">
                            <p class="batch-label">Batch #{{i+1}}</p>
                            </div>
                        </td>
                        <td [ngClass]="{'complete': batch.batchAllocated > 0 && batch.batchAllocated <= batch.batchSize, 'incomplete': batch.batchAllocated > batch.batchSize}" colspan="2">
                            <div class="input-field small">
                                <div style="display: flex;">
                                    <input type="number" placeholder="0" [disabled]="order.fulfilmentStatus == 'ACTION REQUIRED (Order confirmation)' ? false : true" [(ngModel)]="batch.batchAllocated">
                                    <p class="title-label">/{{batch.batchSize}}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr [ngClass]="{'hidden': item.batches && item.batches.length <= 1}" class="small" *ngIf="order.fulfilmentStatus == 'ACTION REQUIRED (Order confirmation)'">
                        <td colspan="1">
                        </td>
                        <td colspan="2">
                            <p>Please adjust the batches if you would like a different split</p>
                        </td>
                        <td [ngClass]="{'incomplete': this.errors[item.product.productCode]}" colspan="2">
                            <p class="title-label">Total Allocated</p>
                            <p class="data-label"><span class="fixed-width">{{getTotalAllocated(item.batches)}}</span>/<span class="data-label">{{item.quantity}}</span></p>
                        </td>
                    </tr>
                    <tr [ngClass]="{'hidden': item.batches && item.batches.length <= 1}" class="small">

                    </tr>
                </tbody>
            </table>
            <div class="total-container">
                <div class="total-rows">
                    <div class="total-item">Subtotal:</div>
                    <div class="total-cost">{{order.orderSubtotal}}</div>
                </div>
                <div class="total-rows" *ngIf="order.bulkDiscount">
                    <div class="total-item">Discount (Bulk order):</div>
                    <div class="total-cost">-{{this.order.bulkDiscount}}</div>
                </div>
                <div class="total-rows" *ngIf="this.order.discountCodeAmount">
                    <div class="total-item">Discount ({{this.order.discountCode}}):</div>
                    <div class="total-cost">-{{this.order.discountCodeAmount}}</div>
                </div>
                <div class="total-rows" *ngIf="order.paymentOption === 'Credit Card Old' || order.paymentOption === 'Bank Transfer Old'">
                    <div class="total-item">Discount* (Payment option)</div>
                    <div class="total-cost">{{'-'+order.orderDiscount}}</div>
                </div>
                <div class="total-rows">
                    <div class="total-item">Shipping:</div>
                    <div class="total-cost">{{productReplaced ? 'Calculated on the next step' : order.getOrderDeliveryQuoteComplete() ? order.shippingTotal : 'Awaiting quote'}}</div>
                </div>
                <div class="total-rows">
                    <div class="total-item">GST:</div>
                    <div class="total-cost">{{order.orderGst}}</div>
                </div>
                <div class="total-rows" *ngIf="order.paymentOption === 'Credit Card'">
                    <div class="total-item">1.85% Credit Card Surcharge:</div>
                    <div class="total-cost">{{order.getSurchargeAmount()}}</div>
                </div>
                <div class="total-rows">
                    <div class="total-item">Total:</div>
                    <div class="total-cost">{{order.orderTotalWithGst}}</div>
                </div>
            </div>
            <div *ngIf="order.fulfilmentStatus == 'ACTION REQUIRED (Order confirmation)'"class="right-align-children">
                <p>{{productReplaced ? 'Updating your order will send it to the suppliers for confirmation.' : order.paymentOption == "Credit Card" ? 'You will be charged and we will begin processing your order.' : 'You will receive an invoice within 1 business day that will need to be paid before your order is processed.'}}</p>
                <div class="primary-button save" [ngClass]="{loading: loading}" (click)="confirmOrder()">{{productReplaced ? 'Update Order' : 'Confirm Order'}}</div>
            </div>
        </div>
    </div>
</div>
